import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/shared/services/order.service';
import { OrderItem } from 'src/app/shared/models/orderItem.model';

export interface DialogData {
    orderItem: OrderItem
}


@Component({
    selector: 'item-delete-confirmation-alert-dialog',
    templateUrl: 'item-delete-confirmation-alert.html',
    styleUrls: ['./item-delete-confirmation-alert.scss'],

})
export class ItemDeleteConfirmationAlertDialog {

    constructor(
        public dialogRef: MatDialogRef<ItemDeleteConfirmationAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private orderService: OrderService) {
        dialogRef.disableClose = true;
    }

    closePopp() {
        this.dialogRef.close();
    }

    isPaied(confirmValue: string) {
        if (confirmValue == "yes") {
            this.orderService.removeOrderItem(this.data.orderItem);
            this.dialogRef.close("deleted");
        } else {
            this.dialogRef.close();
        }
    }
}