import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/shared/services/order.service';

export interface DialogData {
    animal: string;
    name: string;
}


@Component({
    selector: 'delete-confirmation-alert-dialog',
    templateUrl: 'delete-confirmation-alert.html',
    styleUrls: ['./delete-confirmation-alert.scss'],

})
export class DeleteConfirmationAlertDialog {

    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmationAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private orderService: OrderService) {
        dialogRef.disableClose = true;
    }

    closePopp() {
        this.dialogRef.close();
    }

    isPaied(confirmValue: string) {
        if (confirmValue == "yes") {
            this.orderService.removeSelectedOrder();
            this.dialogRef.close();
        } else {
            this.dialogRef.close();
        }
    }
}