import { DiscountType } from "./enums/discount-type.enum";
import { PaymentMethod } from "./enums/payment-method.enum";
import { OrderItem } from "./orderItem.model";
import { OrderType } from "./enums/order-type.enum";
import { Table } from "./table.model";
import { Tax } from "./tax.model";
import { CashDeskMode } from "./enums/cashDesk-mode";
import { Customer } from "./customer.model";

export class Order {
    public localId: number;
    public id: string;
    public tableId: string;
    public referenceOrderId: number;
    public customerId: string;


    public code: string;
    public employeeName: string;
    public date: string;
    public totalAmount: number;
    public paidAmount: number = 0;
    public discount: number;
    public orderType: OrderType;
    public discountType: DiscountType;
    public paymentMethod: PaymentMethod;
    public cashDeskMode : CashDeskMode;
    public items: OrderItem[];
    public notes: string;
    public isSpiltedOrder: boolean;
    public paymentCallbackUrl: string;

    public table: Table;
    public lastIndex: number = 0;
    public customer: Customer;

    /**
     * @returns {number} - Calculate total amount of the order include discount
     */
    getTotalAmountWithDiscount(): number {
        let amount = 0;
        if (this.items && this.items.length > 0) {
            for (let index = 0; index < this.items.length; index++) {
                amount += this.items[index].getTotalPriceIncludeDiscount();
            }
        }
        if (this.discount > 0) {
            if (this.discountType == DiscountType.Amount) {
                amount -= this.discount;
            } else {
                amount -= amount * this.discount / 100;
            }
        }
        return amount;
    }

     /**
     * @returns {string} - get text format of the total amount of the order include discount 2 decimal after comma
     */
    getTotalAmountText():string{
        return this.getTotalAmountWithDiscount().toFixed(2);

    }

    /**
     * @returns {number} - Calculate amount to pay = total amount - already paid amount
     */
    getTotalToPay() : number{
        return this.getTotalAmountWithDiscount() - this.paidAmount;
    }

    /**
     * @returns {string} - get text format of total to pay amount
     */
    getTotalToPayText() : string{
        return this.getTotalToPay().toFixed(2);
    }

    /**
     * @returns {number} - Calculate total amount of the order without discount
     */
    getTotalAmountWithoutDiscount(): number {
        let amount = 0;
        if (this.items && this.items.length > 0) {
            for (let index = 0; index < this.items.length; index++) {
                amount += this.items[index].getTotalPriceIncludedOptions();
            }
        }
        return amount;
    }

    /**
     * @returns {string} - get text format of total amount of the order without discount
     */
    getTotalAmountWithoutDiscountText():string{
        return this.getTotalAmountWithoutDiscount().toFixed(2);
    }

     /**
     * @returns {number} - Calculate total discount of items
     */
    getItemsTotalDiscount(): number{
        let itemsTotalDiscount = 0;
        if (this.items && this.items.length > 0) {
            this.items.forEach(orderItem => {
                itemsTotalDiscount += orderItem.getDiscountAmount();
            });
        }
        return itemsTotalDiscount;
    }

    /**
     * @returns {string} - get text format of Items total discount
     */
    getItemsTotalDiscountText(): string{
        return this.getItemsTotalDiscount().toFixed(2);
    }

    getTotalTaxAmount(): number {
        let amount = 0;
        let amounts = this.getTaxAmounts();
        if (amounts.length > 0) {
            for (let index = 0; index < amounts.length; index++) {
                amount += amounts[index].amount;
            }
        }
        return amount;
    }
    getTaxAmounts(): Tax[] {
        let amounts: Tax[] = new Array();
        if (this.items && this.items.length > 0) {
            for (let index = 0; index < this.items.length; index++) {
                const element = this.items[index];
                if (this.getTaxIndex(amounts, element) == null) {
                    if(element.taxPercentage > 0){
                        amounts.push({
                            key: element.taxPercentage,
                            name: element.taxPercentage + " %",
                            amount: element.getTaxAmount()
                        });
                    }
                } else {
                    amounts[this.getTaxIndex(amounts, element)].amount += element.getTaxAmount();
                }
            }
        }
        return amounts;
    }

    getTaxIndex(taxamounts, item) {
        if (taxamounts && taxamounts.length > 0) {
            for (let index = 0; index < taxamounts.length; index++) {
                if (Number.parseFloat(item.taxPercentage).toString() === Number.parseFloat(taxamounts[index].key).toString()) {
                    return index;
                }
            }
            return null;
        }
        return null;
    }
}