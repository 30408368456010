import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/shared/services/order.service';
import { OrderType } from 'src/app/shared/models/enums/order-type.enum';
import { Table } from 'src/app/shared/models/table.model';
import { Order } from 'src/app/shared/models/order.model';
import { OrderItem } from 'src/app/shared/models/orderItem.model';
import { TableService } from 'src/app/shared/services/table.service';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { CashDesk } from 'src/app/shared/models/cashDesk.model';
import { Product } from 'src/app/shared/models/product.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

export interface DialogData {
  product: Product;
}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'order-list-alert.html',
  styleUrls: ['order-list-alert.scss'],

})
export class OrderTypeAlert implements OnInit {

  reservedTables: Table[] = new Array<Table>();
  device: CashDesk = new CashDesk();
  product: Product = null;


  constructor(
    public dialogRef: MatDialogRef<OrderTypeAlert>,
    private orderService: OrderService,
    private tableService: TableService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    ApplicationEvents.reservedTableUpdate$.subscribe(() => {
      this.reservedTables = this.tableService.reservedTables;
    });
    dialogRef.disableClose = true;
    if (this.data) {
      this.product = this.data.product;
    }
  }

  ngOnInit() { }

  enableTablesFeature(): boolean {
    return this.authService.enableTablesFeature();
  }

  addOrder(type: string): void {
    switch (type) {
      case 'Tafel':
        this.orderService.state = "displayTablesState";
        ApplicationEvents.stateUpdate$.publish();
        if (this.product) {
          this.orderService.setInitProduct(this.product);
        }
        this.dialogRef.close();
        break;
      case 'Meenemen':
        this.dialogRef.close(OrderType.Takeaway);
        break;
      case 'Bezorgen':
        this.dialogRef.close(OrderType.Deliver);
        break;
      case 'PickUp':
        this.dialogRef.close(OrderType.Pickup);
        break;
      default:
        this.dialogRef.close();
        break;
    }
  }

  closePopp() {
    this.dialogRef.close();
  }
}