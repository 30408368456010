import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Injectable } from '@angular/core';
import { Order } from 'src/app/shared/models/order.model';

@Injectable()
export class IndexedDbService {
    constructor(private dbService: NgxIndexedDBService) { }

    getAll(storeName: string): Promise<Order[]> {
        return this.dbService.getAll<Order>(storeName).toPromise();
    }

    addItem(storeName: string, order: Order): Promise<any> {
        return this.dbService.add<Order>(storeName, order).toPromise();
    }

    updateItem(storeName: string, value: Order, onSuccess, onError) {
        this.dbService.update(storeName, value).toPromise().then((data) => {
            onSuccess(data);
            return Promise.resolve();
        }).catch((error) => {
            console.error(JSON.stringify(error));
            onError(error);
        });
    }

    deleteItem(storeName: string, item: Order, onSuccess, onError) {
        this.dbService.delete(storeName, item.localId).toPromise().then((data) => {
            onSuccess(data);
            return Promise.resolve();
        }).catch((error) => {
            console.error(JSON.stringify(error));
            onError(error);
        });
    }
}