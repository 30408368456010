import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authservice: AuthService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      //TODO next line should be removed when implementing authenticatin
       //return true;
    if (this.authservice.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/signin']);
    return false;
  }
}