import { CashDeskMode } from "./enums/cashDesk-mode";
import { Menu } from "./menu.model";
import { Printer } from "./printer.model";

export class CashDesk{
    public id: string;
    public name: string;
    public cashDeskMode: CashDeskMode;
    public macAddress: string;
    public isActive: boolean;
    public printerId: string;
    public menuId: string;

    public menu: Menu;
    public printer: Printer;
}