import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Order } from '../models/order.model';

@Injectable()
export class BankCardTransactionService{
    transactionUrl = "/order/transaction";

    constructor(
        private ApiService: ApiService
    ) { }

    startTransaction(order: Order, onSuccess, onError) {
        order.totalAmount = order.getTotalAmountWithDiscount();
        this.ApiService.post(this.transactionUrl + "/start", order, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    refundTransaction(order: Order, onSuccess, onError) {
        order.totalAmount = order.getTotalAmountWithDiscount();
        this.ApiService.post(this.transactionUrl + "/refund", order, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    stopTransaction(onSuccess, onError) {
        this.ApiService.post(this.transactionUrl + "/stop", {}, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    getTransactionStatus(transactionId: string, onSuccess, onError) {
        this.ApiService.post(this.transactionUrl + "/status/" + transactionId, {}, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    callWebhock(onSuccess, onError){
        this.ApiService.get(this.transactionUrl + "/webhook/12" , (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }
}