import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'delete-confirmation-customer-alert',
    templateUrl: 'delete-confirmation-customer-alert.html',
    styleUrls: ['./delete-confirmation-customer-alert.scss'],

})
export class DeleteConfirmationCustomerAlert {

    constructor(public dialogRef: MatDialogRef<DeleteConfirmationCustomerAlert>) {
        dialogRef.disableClose = true;
    }

    deleteConfirmation(confirmationValue: string){
        this.dialogRef.close(confirmationValue);
    }

    closePopp(){
        this.dialogRef.close();
    }
}