import { Product } from "./product.model";
import { CashDeskMode } from "./enums/cashDesk-mode";

export class ProductTemperature
{
    public productId: string;
    public date: string;
    public value: number;
    public note: string;
    public cashDeskMode: CashDeskMode;


    public product: Product
 }