import { Product } from "./product.model";
import { ProductOptionCategory } from "./productOptionsCategory.model"
import { ImageItem } from "./imageItem.model";

export class ProductCategory {
    public id: string;
    public name: string;
    public products: Product[];
    public options: ProductOptionCategory[];
    public imageItem: ImageItem;
}