import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CashDeskService } from 'src/app/shared/services/cashdesk.service';


@Component({
    selector: 'open-confirmation-alert-dialog',
    templateUrl: 'open-confirmation-alert.html',
    styleUrls: ['./open-confirmation-alert.scss'],

})
export class OpenConfirmationAlertDialog {

    constructor(
        public dialogRef: MatDialogRef<OpenConfirmationAlertDialog>,
        private toastService: ToastService,
        private cashDeskService: CashDeskService,
    ) {}

    closePopp() {
        this.dialogRef.close();
    }

    openCashdek() {
        this.cashDeskService.openCashDesk(()=>{
            this.toastService.showToast("Cash desk is open !");
            this.dialogRef.close();
        },(error)=>{
            this.toastService.showToast('Error !');
        });
    }
}