import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';


@Injectable()
export class ApiService {
    private clientId: number = 2;
    private baseurl: string = "/api";

    constructor(
        private http: HttpClient,
        private router: Router,
        private loadingCtrl: LoadingController,
    ) { }


    async get(url, onSuccess, onError) {
        let loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();
        let finalUrl: string = this.baseurl + url;

        let headersValue = this.getheaders();

        this.http.get(finalUrl, { headers: headersValue })
            .subscribe((data) => {
                loader.dismiss();
                onSuccess(data);
            }, (error) => {
                loader.dismiss();
                onError(error);
                if (error.status == 401) {
                    this.logoutAndNavigateToLoginView();
                }
            });
    }

    private logoutAndNavigateToLoginView() {
        localStorage.removeItem("access_token_pwa");
        this.router.navigate(['/signin'], { replaceUrl: true });
    }

    async post(url, body, onSuccess, onError) {
        let loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();
        let finalUrl: string = this.baseurl + url;

        let headersValue = this.getheaders();

        this.http.post(finalUrl, body, { headers: headersValue })
            .subscribe(data => {
                loader.dismiss();
                onSuccess(data);
            }, (error) => {
                loader.dismiss();
                onError(error);
                if (error.status == 401) {
                    this.logoutAndNavigateToLoginView();
                }
            });
    }

    async put(url, body, onSuccess, onError) {
        let loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();

        let finalUrl: string = this.baseurl + url;

        let headersValue = this.getheaders();
        this.http.put(finalUrl, body, { headers: headersValue }).subscribe(data => {
            onSuccess(data);
            loader.dismiss();
        }, (error) => {
            loader.dismiss();
            onError(error);
            if (error.status == 401) {
                this.logoutAndNavigateToLoginView();
            }
        });
    }

    async delete(url, onSuccess, onError) {
        let loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();

        let finalUrl: string = this.baseurl + url;

        let headersValue = this.getheaders();
        this.http.delete(finalUrl, { headers: headersValue }).subscribe(data => {
            loader.dismiss();
            onSuccess(data);
        }, (error) => {
            loader.dismiss();
            onError(error);
            if (error.status == 401) {
                this.logoutAndNavigateToLoginView();
            }
        });
    }

    private getheaders() {
        return {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'apikey': "681e0802-bab1-4b75-94b3-4832d74294aa",
            'Authorization': localStorage.getItem("access_token_pwa") ? "bearer " + localStorage.getItem("access_token_pwa") : "",
        };
    }

    getClientId() {
        return this.clientId;
    }
}