import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationService } from '../../../shared/services/reservation.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Reservation } from 'src/app/shared/models/reservation.model';

export interface ReservationData {
    reservation: Reservation
}


@Component({
    selector: 'delete-confirmation-reservation-alert',
    templateUrl: 'delete-confirmation-reservation-alert.html',
    styleUrls: ['./delete-confirmation-reservation-alert.scss'],

})
export class DeleteConfirmationReservationAlert {

    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmationReservationAlert>,
        @Inject(MAT_DIALOG_DATA) public data: ReservationData,
        private reservationService: ReservationService) {
        dialogRef.disableClose = true;
    }

    closePopp() {
        this.dialogRef.close();
    }

    isPaied(confirmValue: string) {
        if (confirmValue == "yes") {
            this.reservationService.deleteResevation(this.data.reservation, (data)=>{
                this.dialogRef.close(data);
            }, (error)=>{
                this.dialogRef.close(null);
            });
        } else {
            this.dialogRef.close(null);
        }
    }
}