import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/shared/models/product.model';
import { ProductOption, ProductOptionCategory } from 'src/app/shared/models/productOptionsCategory.model';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductCategory } from 'src/app/shared/models/productCategory.model';
import { OrderItem } from 'src/app/shared/models/orderItem.model';
import { MenuService } from 'src/app/shared/services/menu.service';
import { ItemDeleteConfirmationAlertDialog } from './item-delete-confirmation-alert/item-delete-confirmation-alert';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { TranslateService } from '@ngx-translate/core';
import { AllergenItem } from 'src/app/shared/models/allergenItem.model';
import { DiscountType } from 'src/app/shared/models/enums/discount-type.enum';

export interface DialogData {
  title: string;
  product: Product;
  category: ProductCategory;
  orderItem: OrderItem;
  allergen: AllergenItem[]
}


@Component({
  selector: 'app-product-option-alert',
  templateUrl: './product-option-alert.page.html',
  styleUrls: ['./product-option-alert.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductOptionAlertPage implements OnInit {
  product: Product;
  category: ProductCategory;
  availableProductOptionCategories: ProductOptionCategory[] = new Array<ProductOptionCategory>();
  selectedProductOptionCategories: ProductOptionCategory[] = new Array<ProductOptionCategory>();
  quantity: string = "";
  price: number = this.data.product ? this.data.product.unitPrice : 0;
  orderItem: OrderItem = null;
  productOptions: ProductOptionCategory[] = new Array<ProductOptionCategory>();
  selectedMultiValueNames: string[] = new Array<string>();
  discountType;
  discount = "";
  enabledDiscount: boolean = false;

  allergen: AllergenItem[];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductOptionAlertPage>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private orderService: OrderService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.allergen = this.data.allergen;
    if (this.data.orderItem) {
      this.orderItem = this.data.orderItem;
      this.price = this.orderItem.getTotalPrice();
      this.quantity = this.orderItem.quantity.toString();
      this.enabledDiscount = this.orderItem.discount ? true : false;
      this.discountType = this.orderItem.discountType;
      this.discount = this.orderItem.discount ? this.orderItem.discount.toString() : "";
    } else {
      this.product = this.data.product;
      this.dialogRef.disableClose = true;
    }
    this.category = this.data.category;
    let productId = this.orderItem ? this.orderItem.productId : this.product.id;
    this.productOptions = this.data.category.products.find(p => p.id == productId).options;
    if (this.category.options != null && this.category.options.length > 0) {
      const myClonedArray = Object.assign([], this.category.options);
      for (let index = 0; index < myClonedArray.length; index++) {
        this.availableProductOptionCategories.push(myClonedArray[index]);
      }
    }
    if (this.productOptions != null && this.productOptions.length > 0) {
      const myClonedArray = Object.assign([], this.productOptions);
      for (let index = 0; index < myClonedArray.length; index++) {
        this.availableProductOptionCategories.push(myClonedArray[index]);
      }
    }
    if (this.orderItem) {
      for (let index = 0; index < this.availableProductOptionCategories.length; index++) {
        const element = this.availableProductOptionCategories[index];
        for (let i = 0; i < element.options.length; i++) {
          if(element.hasMultiValue){
            let options =new Array<ProductOption>();
            this.orderItem.options.forEach(oo=>{
              let option = element.options.find(o => o.name == oo.name && o.categoryName == oo.categoryName);
              if (option) {
                let newMainOption = new ProductOptionCategory();
                newMainOption.hasMultiValue = element.hasMultiValue;
                newMainOption.name = element.name;
                newMainOption.options = new Array<ProductOption>();
                newMainOption.options.push(option);
                let exitsOptionPos = this.selectedProductOptionCategories.findIndex(o => o.name == newMainOption.name);
                if (exitsOptionPos >= 0) {
                  if(this.selectedProductOptionCategories[exitsOptionPos].options.findIndex(o => o.name == option.name) < 0){
                    this.selectedProductOptionCategories[exitsOptionPos].options.push(option);
                  }
                } else {
                  this.selectedProductOptionCategories.push(newMainOption);
                }
              }
            })
            
          }else{
            let option = this.orderItem.options.find(o => o.name == element.options[i].name && o.categoryName == element.options[i].categoryName);
          if (option) {
            let newMainOption = new ProductOptionCategory();
            newMainOption.hasMultiValue = element.hasMultiValue;
            newMainOption.name = element.name;
            newMainOption.options = new Array<ProductOption>();
            newMainOption.options.push(option);
            let exitsOptionPos = this.selectedProductOptionCategories.findIndex(o => o.name == newMainOption.name);
            if (exitsOptionPos >= 0) {
              this.selectedProductOptionCategories[exitsOptionPos] = newMainOption;
            } else {
              this.selectedProductOptionCategories.push(newMainOption);
            }
          }
          }
          
        }
      }
    }
  }

  refresh() {
    this.cd.detectChanges();
  }

  onSelectionChange(optionValue, option: ProductOptionCategory) {
    for (let index = 0; index < this.selectedProductOptionCategories.length; index++) {
      const element = this.selectedProductOptionCategories[index];
      if (element.name == option.name) {
        if (option.hasMultiValue) {
          element.options = [];
          for (let i = 0; i < optionValue.length; i++) {
            let multiSelectOption = option.options.find(o => o.name == optionValue[i]);
            element.options.push(multiSelectOption);
          }
        } else {
          element.options = new Array<ProductOption>();
          let simpleOption = option.options.find(o => o.name == optionValue);
          element.options.push(simpleOption);
        }
        this.refresh();
        return;
      }
    }

    const newOption = Object.assign({}, option);
    newOption.options = new Array<ProductOption>();
    if (option.hasMultiValue) {
      for (let i = 0; i < optionValue.length; i++) {
        let multiSelectOption = option.options.find(o => o.name == optionValue[i]);
        newOption.options.push(multiSelectOption);
      }
    } else {
      let simpleOption = option.options.find(o => o.name == optionValue);
      newOption.options.push(simpleOption);
    }

    this.selectedProductOptionCategories.push(newOption);
    this.refresh();
  }

  private getSelectedOptions(): ProductOption[] {
    let selectedOptions: ProductOption[] = new Array<ProductOption>();
    for (let index = 0; index < this.selectedProductOptionCategories.length; index++) {
      const option = this.selectedProductOptionCategories[index];
      option.options.forEach(element => {
        selectedOptions.push(Object.assign({}, element));
      });
    }

    return selectedOptions;
  }

  addOrderItem() {
    this.addProduct();
  }

  closeBtn() {
    this.dialogRef.close();
    this.orderService.selectedItem = null;
    ApplicationEvents.selectedOrderItemUpdate$.publish();
  }

  addProduct() {
    this.quantity = this.quantity != "" ? this.quantity : "1";
    if (parseInt(this.quantity) > 0) {
      if (this.product) {
        this.data.product.quantity = parseInt(this.quantity);
        let discountObject;

        if (this.enabledDiscount) {
          discountObject = {
            discountType: this.discountType,
            discount: parseFloat(this.discount)
          }
        }

        if (this.data.title == "option-exist") {
          this.orderService.addItem(this.data.product, discountObject, this.getSelectedOptions());
        } else {
          this.orderService.addItem(this.data.product, discountObject, null);
        }
        this.closeBtn();
        return;
      }
      if (this.orderItem) {
        if (this.data.title == "option-exist") {
          this.orderItem.quantity = parseInt(this.quantity);
          this.orderItem.options = this.getSelectedOptions();
          if (this.enabledDiscount) {
            this.orderItem.discountType = this.discountType;
            this.orderItem.discount = parseFloat(this.discount);
          } else {
            this.orderItem.discountType = null;
            this.orderItem.discount = null;
          }
          this.orderService.updateOrderItem(this.orderItem);
          this.closeBtn();
          return;
        }
      }
    }
  }

  setQuantity(number: string) {
    this.quantity = this.quantity + number;
  }

  clear() {
    this.quantity = (this.quantity).substring(0, (this.quantity.length - 1));
  }

  getOrderItemImage() {
    if (this.orderItem) {
      let image;
      for (let index = 0; index < this.menuService.selectedMenu.productCategories.length; index++) {
        const category = this.menuService.selectedMenu.productCategories[index];
        for (let i = 0; i < category.products.length; i++) {
          const product = category.products[i];
          if (product.id == this.orderItem.productId) {
            image = product.imageItem;
            break;
          }
        }
      }
      return image;
    }
  }

  deleteOrderItem() {
    const dialogRef = this.dialog.open(ItemDeleteConfirmationAlertDialog, {
      width: '400px',
      data: { orderItem: this.orderItem }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp == "deleted") {
        this.dialogRef.close();
        this.orderService.selectedItem = null;
        ApplicationEvents.selectedOrderItemUpdate$.publish();
      }
    });
  }

  getSelectedOptionName(prodOption: ProductOptionCategory): string {
    if (this.orderItem && this.orderItem.options) {
      for (let index = 0; index < prodOption.options.length; index++) {
        const element = prodOption.options[index];
        let option = this.orderItem.options.find(o => o.name == element.name);
        if (option) {
          return option.name;
        }
      }
    }
    return null;
  }

  getSelectedMultiValueOptionNames(prodOption: ProductOptionCategory) {
    if (this.selectedProductOptionCategories  && prodOption.hasMultiValue) {
      let selectedOptions = new Array<string>();
      let op = this.selectedProductOptionCategories.find(p=>p.name == prodOption.name);
      if(op){
        for (let index = 0; index < op.options.length; index++) {
          selectedOptions.push(op.options[index].name);
        }
      }
      return selectedOptions;
    }
  }

  // discount Methodes - hiden
  showKeybord(){}

  setDiscountType(type: number) {
    if (this.isValidDiscount('', type)) {
      this.discountType = type;
    } else {
      this.translateService.get('product_option_discount_error_message').subscribe(message => {
        alert(message);
      });
    }
  }

  isValidDiscount(discount: string, discountType: DiscountType) {
    let unitePrice = this.data.orderItem ? this.data.orderItem.price : this.data.product.unitPrice;
    if (discountType == DiscountType.Amount) {
      if (discount == '' || discount == '' || parseFloat(discount) >= 0 && parseFloat(discount) <= unitePrice) {
        return true;
      }
    } else if (discountType == DiscountType.Percentage) {
      if (discount == '' || parseInt(discount) > 0 && parseInt(discount) <= 100) {
        return true;
      }
    }

    return false;
  }

  setDiscount(nbr: number) {
    if (this.discountType == 0 || this.discountType == 1) {
      if (nbr == -1) {
        let cptComma = 0;
        for (let index = 0; index < this.discount.length; index++) {
          if (this.discount[index] == ".") {
            cptComma++;
          }
        }
        if (cptComma == 0) {
          if (this.discount != "") {
            this.discount = this.discount + ".";
          }
        }
      } else if (nbr != -1) {
        let val = this.validNumberAfterComma(this.discount);
        if (val == false) {
          let testValue = this.discount + nbr.toString();
          if (this.isValidDiscount(testValue, this.discountType)) {
            this.discount += nbr.toString();
          } else {
            alert("discount is bigger");
          }
        }
      }
    }
  }

  clearDiscount() {
    if (this.discount != "") {
      this.discount = this.discount.substring(0, this.discount.length - 1);
    }
  }

  validNumberAfterComma(value: string) {
    var str = value.split(".", 2);
    if (str.length == 2) {
      if (str[1].length == 2) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getTotalPriceIncludedOptions(): string {
    if (this.orderItem) {
      return this.orderItem.getTotalPriceIncludedOptions().toFixed(2);
    }
    return null;
  }
}

export class ProductOptionTemp {
  public category: string;
  public name: string;
  public price: number;
}