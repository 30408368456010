import { ProductOptionCategory } from './productOptionsCategory.model';
import { ImageItem } from './imageItem.model';
import { ProductTemperature } from './productTemperature.model';
import { AllergenItem } from './allergenItem.model';

export class Product {
    public id: string;
    public code: string;
    public name: string;
    public image: string;
    public unitPrice: number;
    public quantity: number;
    public taxPercentage: number;
    public isPriceIncludedTax: boolean;
    public options: ProductOptionCategory[];
    public imageItem: ImageItem;
    public keepStock: boolean;
    public keepTemperature: boolean;
    public maxTemperature: number;
    public minTemperature: number;

    public stockNumber: number;

    public allergen:AllergenItem[];

    public temperatures: ProductTemperature[];
}