import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderService } from 'src/app/shared/services/order.service';
import { PaymentMethod } from 'src/app/shared/models/enums/payment-method.enum';
import { Order } from 'src/app/shared/models/order.model';
import { ReceiptAlertDialog } from '../receipt-alert/receipt-alert.page';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { BankCardTransactionService } from 'src/app/shared/services/bankcard-transaction.service';
import { SignalRService } from 'src/app/shared/services/signalR.service';


@Component({
  selector: 'pin-alert-dialog',
  templateUrl: 'pin-alert.html',
  styleUrls: ['./pin-alert.scss'],

})
export class PinAlertDialog implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PinAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
    private bankCardTransactionService: BankCardTransactionService,
    private signalRService: SignalRService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.signalRService.updatedOrderPayment.subscribe((order) => {
      console.log("PinAlertDialog: signalRService.updatedOrderPayment ----")
      this.dialogRef.close();
      if (this.orderService.splittedOrder == null) {
        this.registerOrder(this.orderService.selectedOrder, true, () => {
          this.orderService.setSelectedOrder(null);
        });
      } else {
        this.registerOrder(this.orderService.splittedOrder, false, () => {
          this.orderService.splittedOrder = null;
          ApplicationEvents.splittedOrderUpdate$.publish();
        });
      }
    });
  }

  closePopp() {
    this.dialogRef.close();
  }

  isPaied() {
    this.dialogRef.close();
    if (this.orderService.splittedOrder == null) {
        this.registerOrder(this.orderService.selectedOrder,true, ()=>{
            this.orderService.setSelectedOrder(null);
        });
    } else {
        this.registerOrder(this.orderService.splittedOrder,false, ()=>{
            this.orderService.splittedOrder = null;
            ApplicationEvents.splittedOrderUpdate$.publish();
        });
    }
  }

  cancelTerminalPayRequest(){
    this.bankCardTransactionService.stopTransaction((transaction) => {
      console.log("------ stop transaction -------");
      console.log(transaction);
    }, (error) => {
      console.log("------ stop transaction error -------");
      console.log(error);
    });
  }

  startPayRequestToTerminal(){
    this.bankCardTransactionService.startTransaction(this.orderService.selectedOrder, (transaction) => {
      console.log("------ start transaction -------");
      console.log(transaction);
    }, (error) => {
      console.log("------ start transaction error -------");
      console.log(error);
    });
  }

  private registerOrder(order: Order, goBack: boolean, callBack: Function) {
    order.paymentMethod = PaymentMethod.BankCard;
    order.paidAmount = order.getTotalAmountWithDiscount();
    this.orderService.registerOrder(order, (data) => {
      try {
        callBack();
        const dialogRef = this.dialog.open(ReceiptAlertDialog, {
          width: '379px',
          data: { order: order, goBack: goBack }
        });
      } catch (error) {
        console.log("CheckOutPage: Error printerService.printerOrder");
        console.log(error);
      }

    }, (error) => {
      console.log("CheckOutPage: Error registerOrder");
    });
  }
}