import { Menu } from 'src/app/shared/models/menu.model';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { DeviceService } from './device.service';
import { Product } from 'src/app/shared/models/product.model';

@Injectable()
export class MenuService{
    menuUrl = "/Productmenu/";
    selectedMenu: Menu;

    constructor(
        private ApiService: ApiService, 
        private deviceService: DeviceService
    ) { }

    getMenu(onSuccess, onError) {
        this.ApiService.get(this.menuUrl + this.deviceService.getCashdeskFromLocalStorage().id, (data) => {
            this.selectedMenu = data;
            onSuccess(this.selectedMenu);
            ApplicationEvents.menuUpdate$.publish();
        }, (error) => {
            onError(error);
        });
    }

    getProductById(productId: string): Product{
        let prod;
        for (let index = 0; index < this.selectedMenu.productCategories.length; index++) {
            const category = this.selectedMenu.productCategories[index];
            for (let i = 0; i < category.products.length; i++) {
                const product = category.products[i];
                if (product.id == productId) {
                    prod = product;
                    break;
                }
            }
        }
        if (prod) {
            return prod;
        }else{
            return null;
        }
    }
}