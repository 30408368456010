import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";
import { CompanyService } from "../company.service";
import { Router } from "@angular/router";
import * as jwt_decode from 'jwt-decode';
import { SignalRService } from "../signalR.service";

@Injectable()
export class AuthService {
  loginurl = "/auth/login";

  constructor(
    private apiservice: ApiService,
    private companyService: CompanyService,
    private router: Router,
    private signalRService: SignalRService
  ) { }

  login(usernameValue: string, passwordValue: string, onSuccess, onError) {
    var loginData = { username: usernameValue, password: passwordValue };
    this.apiservice.post(this.loginurl, loginData, (data) => {
      localStorage.setItem("access_token_pwa", data.token);
      this.companyService.refresh();
      onSuccess(data);
    }, (error) => {
      console.log("login error");
      onError(error);
    });
  }

  isAuthenticated(): boolean {
    return localStorage.getItem("access_token_pwa") ? true : false;
  }

  signout() {
    this.signalRService.disconnect();
    setTimeout(() => {
      window.location.reload();
      localStorage.removeItem("access_token_pwa");
      setTimeout(() => {
        this.router.navigate(["/signin"], { replaceUrl: true });
      }, 1000);
    }, 500);
  }

  enableTablesFeature(): boolean {
    let tokenDecoded = jwt_decode(localStorage.getItem("access_token_pwa"));
    return tokenDecoded.tables == "True";
  }

  enableReservationFeature(): boolean {
    let tokenDecoded = jwt_decode(localStorage.getItem("access_token_pwa"));
    return tokenDecoded.reservations == "True";
  }

  enableTemperaturesFeature(): boolean {
    let tokenDecoded = jwt_decode(localStorage.getItem("access_token_pwa"));
    return tokenDecoded.temperatures == "True";
  }

  enableOrderTypesFeature(): boolean {
    let tokenDecoded = jwt_decode(localStorage.getItem("access_token_pwa"));
    return tokenDecoded.enableOrderType == "True";
  }

  enablePaymentTerminalsFeature(): boolean {
    let tokenDecoded = jwt_decode(localStorage.getItem("access_token_pwa"));
    return tokenDecoded.enablePaymentTerminals == "True";
  }
}