import { Injectable } from "@angular/core";
import { ApiService } from "src/app/shared/services/api.service";
import { CashDeskTransaction } from "src/app/shared/models/cashDeskTransaction.model";
import { CashDesk } from "src/app/shared/models/cashDesk.model";
import { ApplicationEvents } from "src/app/shared/utils/event.constant";

@Injectable()
export class DeviceService {
    deviceUrl = "/device";
    transactionUrl = this.deviceUrl + "/transaction";
    balanceUrl = this.deviceUrl + "/balance";

    constructor(private apiService: ApiService) { }

    getDevicesInfo(onSuccess, onError) {
        this.apiService.get(this.deviceUrl, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    getCashdeskDetails(onSuccess, onError) {
        this.apiService.get(this.deviceUrl + "/" + this.getCashdeskFromLocalStorage().id, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    updateCashdeskMode(cashdesk: CashDesk, onSuccess, onError) {
        this.apiService.put(this.deviceUrl, cashdesk, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    addCashDeskTransaction(transaction: CashDeskTransaction, onSuccess, onError) {
        transaction.cashDeskMode = this.getCashdeskFromLocalStorage().cashDeskMode;
        this.apiService.post(this.transactionUrl, transaction, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    getBalance(onSuccess, onError) {
        this.apiService.get(this.balanceUrl + "?cashdesk=" + this.getCashdeskFromLocalStorage().id, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    setCashdeskInLocalStorage(cashdesk: CashDesk) {
        localStorage.setItem("cashdesk", JSON.stringify(cashdesk));
        ApplicationEvents.cashdeskUpdate$.publish();
    }

    getCashdeskFromLocalStorage(): CashDesk {
        let cashdesk = JSON.parse(localStorage.getItem("cashdesk")) as CashDesk;
        if (cashdesk) {
            return cashdesk;
        }

        return null;
    }
}