import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Customer } from "src/app/shared/models/customer.model";

@Injectable()
export class CustomerService{
    customerUrl = "/customer";

    constructor(private apiService: ApiService){}

    getCustomers(onSuccess, onError){
        this.apiService.get(this.customerUrl, (data)=>{
            onSuccess(data);
        }, (error)=>{
            onError(error);
        });
    }

    addCustomer(customer: Customer, onSuccess, onError){
        this.apiService.post(this.customerUrl, customer, (data)=>{
            onSuccess(data);
        }, (error)=>{
            onError(error);
        });
    }

    updateCustomer(customer: Customer, onSuccess, onError){
        this.apiService.put(this.customerUrl, customer, (data)=>{
            onSuccess(data);
        }, (error)=>{
            onError(error);
        });
    }

    deleteCustomer(id: string, onSuccess, onError){
        this.apiService.delete(this.customerUrl + "/" + id, (data)=>{
            onSuccess(data);
        }, (error)=>{
            onError(error);
        })
    }
}