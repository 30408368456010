import * as signalR from "@microsoft/signalr";
import { Order } from "src/app/shared/models/order.model";
import { Injectable, EventEmitter } from "@angular/core";
import { ApplicationEvents } from "src/app/shared/utils/event.constant";
import { NgxIndexedDBService } from "ngx-indexed-db";

@Injectable()
export class SignalRService {
    hubConnection: signalR.HubConnection;
    newOrderReceived = new EventEmitter<Order>();
    deletedOrder = new EventEmitter<Order>();
    updatedOrder = new EventEmitter<Order>();
    updatedOrderPayment = new EventEmitter<Order>();

    ordersByProduct = new EventEmitter<any[]>();
    countClients = new EventEmitter<Number>();


    constructor(
        private dbService: NgxIndexedDBService
    ) {
        this.initSignalR();
    }

    initSignalR() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/api/orderhub", {
                accessTokenFactory: () => localStorage.getItem("access_token_pwa") || ''
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        window.addEventListener("pagehide", () => {
            this.disconnect(); // Explicitly stop the connection
        });
        window.addEventListener("pagehide", () => {
            this.disconnect();
        });

        this.hubConnection.onclose(() => {
            console.log("hubConnection.onclose");
            this.dbService.clear("orders");
            this.publishHubConnectionState();
        });

        this.hubConnection.onreconnecting(() => {
            console.log("hubConnection.onreconnecting");
            this.publishHubConnectionState();
        });

        this.hubConnection.onreconnected(() => {
            console.log("hubConnection.onreconnected");

            this.publishHubConnectionState();
        });

        this.hubConnection.on("ReceiveNewOrder", (order: Order) => {
            this.newOrderReceived.emit(order);
        });

        this.hubConnection.on("DeleteOrder", (order: Order) => {
            this.deletedOrder.emit(order);
        });

        this.hubConnection.on("UpdateOrder", (order: Order) => {
            console.log("SignalRService", "UpdateOrder Received: " + order.localId);
            this.updatedOrder.emit(order);
        });

        this.hubConnection.on("CountClients", (count: Number) => {
            this.countClients.emit(count);
        });

        this.hubConnection.on("OrdersByProduct", (order: any[]) => {
            this.ordersByProduct.emit(order);
        });

        this.hubConnection.on("ReceivePaymentUpdate", (order: Order) => {
            console.log("SignalRService: ReceivePaymentUpdate ----------------------");
            this.updatedOrderPayment.emit(order);
        });
    }

    disconnect() {
        this.hubConnection.stop();
    }
    addNewOrder(order: Order) {
        this.hubConnection.send("NewOrder", order)
            .then(() => console.log("SignalRService", "new order !!.."));
    }

    deleteOrder(order: Order) {
        this.hubConnection.send("deleteOrder", order)
            .then(() => console.log("SignalRService", "delete order !!.."));
    }

    updateOrder(order: Order) {
        this.hubConnection.send("updateOrder", order)
            .then(() => console.log("SignalRService", "update order !!.."));
    }

    getClientsCount() {
        this.hubConnection.send("CountClients")
            .then(() => console.log("SignalRService", "getClientsCount !!.."));
    }

    getOrdersByProduct() {
        this.hubConnection.send("OrdersByProduct")
            .then(() => console.log("SignalRService", "getOrdersByProduct !!.."));
    }

    publishHubConnectionState() {
        ApplicationEvents.connectionStateUpdate$.publish();
    }

    startConnection() {
        if (this.hubConnection.state == "Disconnected") {
            this.dbService.clear("orders");
            this.hubConnection
                .start()
                .then(() => {
                    this.publishHubConnectionState();
                })
                .catch(err => {
                    this.publishHubConnectionState();
                    this.startConnection();
                });
        }
    }
}