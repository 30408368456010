import { Gender } from "./enums/gender.enum";

export class Customer{
    public id: string;
    public firstName: string;
    public lastName: string;
    public gender: Gender;
    public email: string;
    public phone: string;
    public address: string;
    public postalcode: string;
    public city: string;
    public isCompany: boolean;
    public companyName: string;
    public chamberOfCommerceNumber: string;
    public companyVatNumber: string;
}