import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PrinterService } from './printer.service';
import { DeviceService } from './device.service';

@Injectable()
export class CashDeskService{
    url = "/Cashdesk/";
    openUrl = "/device/open?id=" + this.deviceService.getCashdeskFromLocalStorage().id;

    constructor(
        private printerService: PrinterService,
        private apiService: ApiService,
        private deviceService :DeviceService
    ) { }

    openCashDesk(onSuccess, onError) {
        this.printerService.openCashDrawer();
        this.apiService.post(this.openUrl, {}, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }
}