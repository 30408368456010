import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Customer } from 'src/app/shared/models/customer.model';


@Component({
    selector: 'edit-customer-alert',
    templateUrl: 'edit-customer-alert.html',
    styleUrls: ['./edit-customer-alert.scss'],
})
export class EditCustomerAlert implements OnInit{
    customerForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<EditCustomerAlert>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) 
    {
        dialogRef.disableClose = true;
    }

    buildItemForm(customer: Customer) {
        this.customerForm = this.fb.group({
          firstName: this.fb.control( customer?.firstName || '', Validators.required),
          lastName: this.fb.control( customer?.lastName || '', Validators.required),
          email: this.fb.control( customer?.email || '' ),
          phone: this.fb.control( customer?.phone || '' ),
          address: this.fb.control( customer?.address || '' ),
          postalCode: this.fb.control( customer?.postalcode || '' ),
          city: this.fb.control( customer?.city || '' ),
          isCompany: this.fb.control( customer?.isCompany || false ),
          companyName: this.fb.control( customer?.companyName || '' ),
          chamberOfCommerceNumber : this.fb.control( customer?.chamberOfCommerceNumber || '' ),
          companyVatNumber: this.fb.control( customer?.companyVatNumber || '' )
        });
    }

    ngOnInit(){
        this.buildItemForm(this.data.customer);
    }

    submit(){
        if (this.customerForm.valid) {
            this.dialogRef.close(this.customerForm.value);
        }
    }
}