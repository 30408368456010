import { Component, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BrowserQRCodeReader, MultiFormatReader, BrowserMultiFormatReader } from '@zxing/library';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { OrderService } from 'src/app/shared/services/order.service';

export interface DialogData {
    animal: string;
    name: string;
}


@Component({
    selector: 'bar-code-scanner',
    templateUrl: 'bar-code-scanner.html',
    styleUrls: ['./bar-code-scanner.scss'],

})
export class BadrCodeScannerAlert {
    @ViewChild('manualBarCodeValue', { static: false }) manualBarCodeValue: any;
    codeReader = new BrowserMultiFormatReader();
    displayCamera: boolean = false;
    barcodeValue;

    constructor(
        public dialogRef: MatDialogRef<BadrCodeScannerAlert>,
        private menuService: MenuService,
        private orderService: OrderService
    ) {
        dialogRef.disableClose = true;
    }

    displayCameraButtonClick() {
        this.displayCamera = true;
        this.codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                this.onStarted(videoInputDevices[0].deviceId);
            })
            .catch(err => { });
    }

    onStarted(deviceId) {
        this.codeReader.decodeFromVideoDevice(deviceId, 'video', (result: any) => {
            if (result) {
                this.barcodeValue = result.text;
                for (let index = 0; index < this.menuService.selectedMenu.productCategories.length; index++) {
                    const category = this.menuService.selectedMenu.productCategories[index];
                    for (let j = 0; j < category.products.length; j++) {
                        const product = category.products[j];
                        if (product.code == result.text) {
                            this.orderService.addItem(product, null, null);
                            this.codeReader.reset();
                            this.onStarted(deviceId);
                        }
                    }
                }
            }
        });
    }

    closePopp() {
        this.codeReader.reset();
        this.dialogRef.close();
    }

    addProductByCheckedButton(event) {
        if (event.keyCode === 13) {
            for (let index = 0; index < this.menuService.selectedMenu.productCategories.length; index++) {
                const category = this.menuService.selectedMenu.productCategories[index];
                for (let j = 0; j < category.products.length; j++) {
                    const product = category.products[j];
                    if (product.code && product.code != "" && this.manualBarCodeValue.value && this.manualBarCodeValue.value != "" && product.code == this.manualBarCodeValue.value) {
                        this.orderService.addItem(product, null, null);
                        this.manualBarCodeValue.value = "";
                    }
                }
            }
        }
    }
}