import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PaymentMethod } from 'src/app/shared/models/enums/payment-method.enum';

export interface dataDialog{
    paymentMethod: PaymentMethod;
}

@Component({
    selector: 'retour-mode-alert-dialog',
    templateUrl: 'retour-mode-alert.html',
    styleUrls: ['./retour-mode-alert.scss'],
})
export class RetourModeAlertDialog implements OnInit{
    activatedPaymentMethode: string;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<RetourModeAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: dataDialog,
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(){
        this.activatedPaymentMethode = this.data.paymentMethod.toString();
    }

    onChangePaymentMethode(event: any){
        this.activatedPaymentMethode = event.detail.value;
    }

    onSave(){
        this.dialogRef.close(this.activatedPaymentMethode);
    }

    onClose(){
        this.dialogRef.close();
    }
}