import { Table } from 'src/app/shared/models/table.model';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { Order } from 'src/app/shared/models/order.model';

@Injectable()
export class TableService {

    tableUrl = "/table";
    reservedTables: Table[] = new Array<Table>();
    tables: Table[] = new Array<Table>();
    constructor(
        private apiService: ApiService,
    ) { }

    getTables(onSuccess, onError) {
        this.apiService.get(this.tableUrl, (data) => {
            //todo reset this line
            this.tables = data.slice(0, 30);
            onSuccess(this.tables);
        }, (error) => {
            onError(error);
        })
    }

    getTableNameById(id: string) {
        for (let index = 0; index < this.tables.length; index++) {
            if (this.tables[index].id == id) return this.tables[index].name;
        }
        return "";
    }

    resetReservedTables(orders: Order[]) {
        this.reservedTables = new Array<Table>();
        if (orders) {
            for (let index = 0; index < orders.length; index++) {
                const element = orders[index];
                if (element.tableId) {
                    this.reservedTables.push(element.table);
                }
            }
        }
        ApplicationEvents.reservedTableUpdate$.publish();
    }

    isTableFree(table: Table) {
        console.log("check if table is free....");
        return !this.reservedTables.some(p=>p.id == table.id);
    }
}