import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Customer } from 'src/app/shared/models/customer.model';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { EditCustomerAlert } from './edit-customer-alert/edit-customer-alert';
import { AlertController } from '@ionic/angular';
import { DeleteConfirmationCustomerAlert } from './delete-confirmation-customer-alert/delete-confirmation-customer-alert';


@Component({
    selector: 'selected-customer-alert',
    templateUrl: 'selected-customer-alert.html',
    styleUrls: ['./selected-customer-alert.scss'],
})
export class SelectedCustomerAlert implements OnInit {
    customers: Customer[] = new Array<Customer>();
    selectedCustomerId: string;

    listToFilter = [];

    constructor(
        public dialogRef: MatDialogRef<SelectedCustomerAlert>,
        private customerService: CustomerService,
        private orderService: OrderService,
        public dialog: MatDialog,
        public alertController: AlertController
    ) { }

    ngOnInit() {
        this.customerService.getCustomers((data) => {
            this.customers = data;
            this.listToFilter = this.customers;
            if (this.orderService.selectedOrder && this.orderService.selectedOrder.customerId) {
                this.selectedCustomerId = this.orderService.selectedOrder.customerId;
            } else if (this.orderService.splittedOrder && this.orderService.splittedOrder.customerId) {
                this.selectedCustomerId = this.orderService.splittedOrder.customerId;
            }
        }, (error) => {
            console.log(error.message);
        });
    }

    onSelectCustomer(customer: Customer) {
        this.dialogRef.close(customer);
    }

    onAddCustomer(customer: Customer, isNew: boolean) {
        customer = isNew ? new Customer() : customer;
        let title = isNew ? "edit_customer_add" : "edit_customer_update";

        const dialogRef = this.dialog.open(EditCustomerAlert, {
            data: { title: title, customer: customer }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return null;
            }
            //todo 
            //replace backend methodes to the alert and return the result
            if (isNew) {
                this.customerService.addCustomer(res, (data) => {
                    this.customers.push(data);
                }, (error) => {
                    console.log(error.message);
                });
            } else {
                res.id = customer.id;
                this.customerService.updateCustomer(res, (data) => {
                    let pos = this.customers.findIndex(c => c.id == data.id);
                    if (pos) {
                        this.customers[pos] = data;
                    }
                }, (error) => {
                    console.log(error.message);
                });
            }
        });
    }

    deleteCustomer(customerId: string) {
        const dialogRef = this.dialog.open(DeleteConfirmationCustomerAlert, {
            width: "419px"
        });

        dialogRef.afterClosed().subscribe((res: string) => {
            if (!res) {
                return null;
            }

            if (res == 'yes') {
                this.customerService.deleteCustomer(customerId, (data) => {
                    let pos = this.customers.findIndex(c => c.id == customerId);
                    if (pos) {
                        this.customers.splice(pos, 1);
                    }
                }, (error) => {
                    console.log(error.message);
                });
            }
        });
    }

    onClearAll() {
        this.searchCustomer("");
    }

    searchCustomer(searchingValue: any) {
        let lowerCaseValue = searchingValue.value.toLowerCase();
        const listFiltered = this.listToFilter.filter((c: Customer) => {
            return this.filterByLastName(c, lowerCaseValue)
                || this.filterByFirstName(c, lowerCaseValue)
                || this.filterByEmail(c, lowerCaseValue)
                || this.filterByPhone(c, lowerCaseValue);
        });

        // update the rows
        this.customers = listFiltered;
    }

    filterByPhone(c: Customer, searchValue: string): boolean {
        return c.phone.includes(searchValue);
    }

    filterByEmail(c: Customer, searchValue: string): boolean {
        return c.email.toLowerCase().includes(searchValue);
    }

    filterByFirstName(c: Customer, searchValue: string): boolean {
        return c.firstName.toLowerCase().includes(searchValue);
    }

    filterByLastName(c: Customer, searchValue: string): boolean {
        return c.lastName.toLowerCase().includes(searchValue);
    }
}