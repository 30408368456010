import { Reservation } from 'src/app/shared/models/reservation.model';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { DeviceService } from './device.service';
import { CalendarEvent } from 'angular-calendar';
import { EventColor } from 'calendar-utils';

@Injectable()
export class ReservationService {
  reservationUrl = "/reservation";
  searchUrl = this.reservationUrl + "/search?searchValue=";
  cahsdesk = this.deviceService.getCashdeskFromLocalStorage();

  constructor(
    private apiService: ApiService,
    private deviceService: DeviceService
  ) { }

  getReservations(onSuccess, onError) {
    this.apiService.get(this.reservationUrl, (data) => {
      const mappedReservations = this.populateReservationCalendars(data);
      onSuccess(mappedReservations);
    }, (error) => {
      onError(error);
    });
  }

  addReservation(reservation: Reservation, onSuccess, onError) {
    reservation.cashDeskMode = this.cahsdesk.cashDeskMode;
    this.apiService.post(this.reservationUrl, reservation, (data: Reservation) => {
      const newReservationn = {
        ...data,
        event: this.createCalendarEvent(data)
      }
      onSuccess(newReservationn);
    }, (error) => {
      onError(error);
    });
  }

  updateReservation(reservation: Reservation, onSuccess, onError) {
    reservation.cashDeskMode = this.cahsdesk.cashDeskMode;
    this.apiService.put(this.reservationUrl, reservation, (data) => {
      const updatedReservationn = {
        ...data,
        event: this.createCalendarEvent(data)
      }
      onSuccess(updatedReservationn);
    }, (error) => {
      onError(error);
    });
  }

  deleteResevation(reservation: Reservation, onSuccess, onError) {
    this.apiService.delete(this.reservationUrl + "/" + reservation.id, (data) => {
      onSuccess(data);
    }, (error) => {
      onError(error);
    });
  }

  searchForReservation(searchValue: string, onSuccess, onError) {
    this.apiService.get(this.searchUrl + searchValue, (data) => {
      const mappedReservations = this.populateReservationCalendars(data);
      onSuccess(mappedReservations);
    }, (error) => {
      onError(error);
    });
  }

  private populateReservationCalendars(reservations: Reservation[]): Reservation[] {
    return reservations.map((reservation) => ({
      ...reservation,
      event: this.createCalendarEvent(reservation),
    })).sort((a, b) => {
      const dateA = a.date.split('T')[0];
      const dateB = b.date.split('T')[0];
      return dateB.localeCompare(dateA);
    });
  }

  private createCalendarEvent(reservation: Reservation): CalendarEvent {
    return {
      id: reservation.id,
      start: new Date(reservation.date),
      end: this.calculateEventEnd(reservation),
      title: `${reservation.customerName} (${reservation.customerEmail} - ${reservation.customerPhone})`,
      color: this.getEventColor(),
      allDay: false,
    };
  }

  private calculateEventEnd(reservation: Reservation): Date {
    const start = new Date(reservation.date);
    return new Date(start.getTime());
  }

  private getEventColor(): EventColor {
    return {
      primary: '#f86249',
      secondary: '#FAE3E3',
    };
  }
}