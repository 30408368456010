import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-settings-access-popup',
  templateUrl: './settings-access-popup.page.html',
  styleUrls: ['./settings-access-popup.page.scss'],
})
export class SettingsAccessPopupPage implements OnInit {
  pin1: string = "";
  pin2: string = "";
  pin3: string = "";
  pin4: string = "";
  isRight: string = "";
  shakedError: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SettingsAccessPopupPage>,
    private router: Router,
    private loadingCtrl: LoadingController,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}


  closePopp() {
    this.dialogRef.close();
  }

  clickNumber(pin: string) {
    if (this.pin4 == "") {
      if (this.pin1 == "") {
        this.pin1 = pin;
      }
      else {
        if (this.pin2 != "") {
          if (this.pin3 != "") {
            this.pin4 = pin;
          }
          else {
            this.pin3 = pin;
          }
        }
        else {
          this.pin2 = pin;
        }
      }
    }
    if (this.pin4 != "") {
      let password = localStorage.getItem("password");
      if (this.pin1 == password[0] && this.pin2 == password[1] && this.pin3 == password[2] && this.pin4 == password[3]) {
        this.isRight = "Log";
        this.loadData();
        this.dialogRef.close();
        this.isRight = "";
        this.pin1 = "";
        this.pin2 = "";
        this.pin3 = "";
        this.pin4 = "";
      }
      else {
        this.shakedError = false;
        this.isRight = "noLog";
        setTimeout(() => {
          this.isRight = "";
          this.shakedError = true;
          if (this.pin4 != "") {
            this.pin1 = "";
            this.pin2 = "";
            this.pin3 = "";
            this.pin4 = "";
          }
        }, 350);
      }
    }
  }

  clearCodePin() {
    if (this.pin4 != "") {
      this.pin4 = "";
    } else {
      if (this.pin3 != "") {
        this.pin3 = "";
      } else {
        if (this.pin2 != "") {
          this.pin2 = "";
        } else {
          if (this.pin1 != "") {
            this.pin1 = "";
          }
        }
      }
    }
  }
  async loadData() {
    let loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
    await loader.present();
    this.router.navigate(['/report']);
    loader.dismiss();
  }
}
