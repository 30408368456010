import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      localStorage.setItem("password","1999");
      let language = localStorage.getItem("language");
    if(language){
      this.translate.setDefaultLang(language);
    }else{
      localStorage.setItem("language", "nl");
      this.translate.setDefaultLang('nl');
    }
    });
  }
}
