import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  public loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.buildItemForm();
  }

  buildItemForm() {
    this.loginForm = this.fb.group({
      userName: this.fb.control( '', [Validators.required]),
      password: this.fb.control( '', [Validators.required]),
    });
  }

  submit(){
    let formValue = this.loginForm.value;
    this.authService.login(formValue.userName, formValue.password, (data)=>{
      this.router.navigateByUrl("/tabs", { replaceUrl: true });
    }, (error)=>{
      this.toastService.showToast("password or username is incorrect!");
    });
  }
  isMobile() : boolean{
    return this.deviceService.isMobile();
  }
}
