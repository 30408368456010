import { CashDesk } from "./cashDesk.model";
import { CashDeskMode } from "./enums/cashDesk-mode";

export class CashDeskTransaction{
    public id: string;
    public date: string;
    public amount: number;
    public remarks: string;
    public cashDeskId: string;
    public cashDeskMode: CashDeskMode;

    public cashDesk: CashDesk;
}