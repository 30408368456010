import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DailyReport } from 'src/app/shared/models/dailyreport.model';

@Injectable()
export class DailyReportService{
    todayReportUrl = "/report/";
    
    constructor(private ApiService: ApiService) { }

    get(days,onSuccess, onError) {
        this.ApiService.post(this.todayReportUrl, {}, (data) => {
            onSuccess(data as DailyReport);
        }, (error) => {
            onError(error);
        });
    }
}