import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Router } from '@angular/router';


@Component({
    selector: 'signout-confirmation-alert-dialog',
    templateUrl: 'signout-confirmation-alert.html',
    styleUrls: ['./signout-confirmation-alert.scss'],

})
export class SignoutConfirmationAlertDialog {

    constructor(
        public dialogRef: MatDialogRef<SignoutConfirmationAlertDialog>,
        private authService: AuthService,
        private router: Router
    ) {}

    closePopp() {
        this.dialogRef.close();
    }

    signout() {
        this.authService.signout();
        this.dialogRef.close();
    }
}