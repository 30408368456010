import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NoInternetConnection } from 'src/custom-errors/no-internet-connection.error';
import { ApplicationInsightService } from './applicationInsight.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

    constructor(private applicationInsightService: ApplicationInsightService, private toastService: ToastService) {
        super();
    }

    handleError(error: Error): void {
        this.applicationInsightService.logException(error);
    }

    handleErrorAndShowToast(error: Error): void {
        this.handleError(error);
        this.toastService.showToast(this.getErrorMessage(error));
    }

    getErrorMessage(error: Error): string {
        if (error instanceof HttpErrorResponse) {
            // Server error
            switch (error.status) {
                case 400: //Bad Request
                    return 'Het verzoek kan niet verwerkt worden';
                case 401: //Unauthorized
                case 403: //forbidden
                    return 'Je hebt geen rechten om deze gegevens te bekijken.';
                case 404: //Not Found
                    return 'Opgevraagd informatie kan helaas niet gevonden worden.';
                default:
                    return 'Er is een fout opgetreden, probeer het later nog een keer.';
            }
        } else if (error instanceof NoInternetConnection) {
            return 'Controleer je internet verbinding en probeer opnieuw.';
        } else {
            // Client Error
            return 'Er is een fout opgetreden, probeer het later nog een keer.';
        }
    }
}
