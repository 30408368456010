import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CashDeskTransaction } from 'src/app/shared/models/cashDeskTransaction.model';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DatePipe } from '@angular/common';
import { CashDesk } from 'src/app/shared/models/cashDesk.model';


@Component({
    selector: 'balance-alert-dialog',
    templateUrl: 'balance-alert.html',
    styleUrls: ['./balance-alert.scss'],
})
export class BalanceAlertDialog implements OnInit{
    @ViewChild("remarks", {static:false}) remarks: any;
    price: string = " ";
    cashdesk: CashDesk = new CashDesk();
    currentDate: string;
    
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<BalanceAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private deviceService: DeviceService,
        private datePipe: DatePipe
         ) {
        dialogRef.disableClose = true;
        this.cashdesk = this.deviceService.getCashdeskFromLocalStorage();
    }

    ngOnInit(){
        this.currentDate = this.getCurrentDate();
    }

    getNumber(number: string) {
        let cptComma = 0;
        for (let index = 0; index < this.price.length; index++) {
            if (this.price[index] == ".") {
                cptComma++;
            }
        }
        if (cptComma == 0) {
            this.price = this.price + number;
        } else if (cptComma == 1) {
            let val = this.getNumberAfterComma(this.price);
            if (val == false && number != ".") {
                this.price = this.price + number;
            }
        }
    }

    clear() {
        this.price = (this.price).substring(0, (this.price.length - 1));
    }

    getNumberAfterComma(value: string) {
        var str = value.split(".", 2);
        if (str.length == 2) {
            if (str[1].length == 2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    closePopp() {
        this.dialogRef.close();
    }

 

    async withdrawDepositMoney() {
        let cashDeskTransaction = new CashDeskTransaction();

        if (this.data.balanceMode == 'withdraw') {
            let amount = parseFloat(this.price) * (-1);
            cashDeskTransaction.amount = amount;
        }else{
            cashDeskTransaction.amount = parseFloat(this.price);
        }
        cashDeskTransaction.cashDeskId = this.cashdesk.id;
        cashDeskTransaction.remarks = this.remarks.value;
        cashDeskTransaction.date = this.datePipe.transform(this.currentDate, "yyyy-MM-ddTHH:mm");
        
        this.deviceService.addCashDeskTransaction(cashDeskTransaction, (data)=>{
            this.dialogRef.close("ok.");
        }, (error)=>{
            this.dialogRef.close("error !");
        });
    }

    getCurrentDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
}