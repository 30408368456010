import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Product } from 'src/app/shared/models/product.model';
import { ProductCategory } from 'src/app/shared/models/productCategory.model';
import { ProductOptionAlertPage } from '../product-option-alert/product-option-alert.page';

export interface DialogData {
    product: Product,
    title: string,
    category: ProductCategory
}


@Component({
    selector: 'product-amount-alert',
    templateUrl: 'product-amount-alert.html',
    styleUrls: ['./product-amount-alert.scss'],

})
export class ProductAmountAlertDialog {
    price: string = "";

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProductAmountAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        dialogRef.disableClose = true;
    }

    checkOutMoney(number: string) {
        let cptComma = 0;
        for (let index = 0; index < this.price.length; index++) {
            if (this.price[index] == ".") {
                cptComma++;
            }
        }
        if (cptComma == 0) {
            this.price = this.price + number;
        } else if (cptComma == 1) {
            let val = this.getNumberAfterComma(this.price);
            if (val == false && number != ".") {
                this.price = this.price + number;
            }
        }
    }

    clear() {
        if (this.price != "") {
            this.price = (this.price).substring(0, (this.price.length - 1));
        }
    }

    getNumberAfterComma(value: string) {
        var str = value.split(".", 2);
        if (str.length == 2) {
            if (str[1].length == 2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    closePopp() {
        this.dialogRef.close();
    }

    addProduct() {
        let product = new Product();
        product.id = this.data.product.id;
        product.name = this.data.product.name;
        product.unitPrice = parseFloat(this.price);
        product.quantity = this.data.product.quantity;
        product.taxPercentage = this.data.product.taxPercentage;
        product.isPriceIncludedTax = this.data.product.isPriceIncludedTax;
        product.options = this.data.product.options;
        product.imageItem = this.data.product.imageItem;
        const dialogRef = this.dialog.open(ProductOptionAlertPage, {
            panelClass: "pop-up-class",
            height: '90%',
            data: { title: this.data.title, product: product, category: this.data.category, allergen: this.data.product.allergen }
        });
        this.dialogRef.close();
    }
}