interface ILiteEvent<T> {
    subscribe(handler: { (data?: T): void }) : void;
    unsubscribe(handler: { (data?: T): void }) : void;
}

class LiteEvent<T> implements ILiteEvent<T> {
    private handlers: { (data?: T): void; }[] = [];

    public subscribe(handler: { (data?: T): void }) : void {
        this.handlers.push(handler);
    }

    public unsubscribe(handler: { (data?: T): void }) : void {
        this.handlers = this.handlers.filter(h => h !== handler);
    }

    public publish(data?: T) {
        this.handlers.slice(0).forEach(h => h(data));
    }

    public expose() : ILiteEvent<T> {
        return this;
    }
}

export class ApplicationEvents {
    static ordersUpdate$ = new LiteEvent<any>();
    static selectedOrderUpdate$ = new LiteEvent<any>();
    static selectedOrderItemUpdate$ = new LiteEvent<any>();
    static splittedOrderUpdate$ = new LiteEvent<any>();
    static reservedTableUpdate$ = new LiteEvent<any>();
    static stateUpdate$ = new LiteEvent<any>();
    static productCategoryUpdate$ = new LiteEvent<any>();
    static menuUpdate$ = new LiteEvent<any>();
    static printerStatusUpdate$ = new LiteEvent<any>();
    static reservationUpdate$ = new LiteEvent<any>();
    static cashdeskUpdate$ = new LiteEvent<any>();
    static connectionStateUpdate$ = new LiteEvent<any>();
    static initProductUpdate$ = new LiteEvent<any>();
}