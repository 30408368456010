import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderService } from 'src/app/shared/services/order.service';
import { TableService } from 'src/app/shared/services/table.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderTypeAlert } from './views/home/orders-list/orders-list-alert/order-list-alert';
import { CashAlertDialog } from './views/check-out/cash-alert/cash-alert';
import { RestAlertDialog } from './views/check-out/cash-alert/rest-alert/rest-alert';
import { PinAlertDialog } from './views/check-out/pin-alert/pin-alert';
import { DeleteConfirmationAlertDialog } from './views/home/shopping-cart/delete-confirmation-alert/delete-confirmation-alert';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ProductAmountAlertDialog } from './views/home/products/product-amount-alert/product-amount-alert';
import { PrinterService } from 'src/app/shared/services/printer.service';
import { DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DailyReportService } from 'src/app/shared/services/dailyreport.service';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { IndexedDbService } from 'src/app/shared/services/indexedDb.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { DeleteConfirmationReservationAlert } from './views/reservation/delete-confirmation-reservation-alert/delete-confirmation-reservation-alert';
import { ReservationService } from './shared/services/reservation.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { BalanceAlertDialog } from './views/settings/report/balance-alert/balance-alert';
import { AuthGuard } from 'src/app/shared/services/auth/auth.guard';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { OpenConfirmationAlertDialog } from './views/settings/open-confirmation-alert/open-confirmation-alert';
import { SigninComponent } from './views/signin/signin.component';
import { NewTemperatureAlertDialog } from './views/temperatures/new-temperature-popup/new-temperature-popup';
import { SignoutConfirmationAlertDialog } from './views/tabs/signout-confirmation-alert/signout-confirmation-alert';
import { ReceiptAlertPageModule } from './views/check-out/receipt-alert/receipt-alert.module';
import { ProductOptionAlertPage } from './views/home/products/product-option-alert/product-option-alert.page';
import { ProductOptionAlertPageModule } from './views/home/products/product-option-alert/product-option-alert.module';
import { SettingsAccessPopupPage } from './views/settings/settings-access-popup/settings-access-popup.page';
import { SettingsAccessPopupPageModule } from './views/settings/settings-access-popup/settings-access-popup.module';
import { BadrCodeScannerAlert } from './views/home/products/bar-code-scanner/bar-code-scanner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SignalRService } from 'src/app/shared/services/signalR.service';
import { CashDeskService } from 'src/app/shared/services/cashdesk.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ItemDeleteConfirmationAlertDialog } from './views/home/products/product-option-alert/item-delete-confirmation-alert/item-delete-confirmation-alert';
import { SelectedCustomerAlert } from './views/check-out/selected-customer-alert/selected-customer-alert';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { EditCustomerAlert } from './views/check-out/selected-customer-alert/edit-customer-alert/edit-customer-alert';
import { DeleteConfirmationCustomerAlert } from './views/check-out/selected-customer-alert/delete-confirmation-customer-alert/delete-confirmation-customer-alert';
import { PartialAlertDialog } from './views/check-out/partial-alert/partial-alert';
import { RetourModeAlertDialog } from './views/transactions/retour-mode-alert/retour-mode-alert';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { BankCardTransactionService } from './shared/services/bankcard-transaction.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


const dbConfig: DBConfig = {
  name: 'BuuqsDb',
  version: 2,
  objectStoresMeta: [{
    store: 'orders',
    storeConfig: { keyPath: 'localId', autoIncrement: true },
    storeSchema: [
      { name: 'tableId', keypath: 'tableId', options: { unique: false } },
      { name: 'date', keypath: 'date', options: { unique: false } },
      { name: 'orderType', keypath: 'orderType', options: { unique: false } },
    ]
  },],
};

@NgModule({
  declarations: [
    AppComponent,
    OrderTypeAlert,
    CashAlertDialog,
    RestAlertDialog,
    PinAlertDialog,
    DeleteConfirmationAlertDialog,
    DeleteConfirmationReservationAlert,
    ItemDeleteConfirmationAlertDialog,
    ProductAmountAlertDialog,
    BalanceAlertDialog,
    OpenConfirmationAlertDialog,
    NewTemperatureAlertDialog,
    SigninComponent,
    SignoutConfirmationAlertDialog,
    BadrCodeScannerAlert,
    SelectedCustomerAlert,
    EditCustomerAlert,
    DeleteConfirmationCustomerAlert,
    PartialAlertDialog,
    RetourModeAlertDialog
  ],
  entryComponents: [
    ProductOptionAlertPage,
    OrderTypeAlert,
    CashAlertDialog,
    RestAlertDialog,
    PinAlertDialog,
    DeleteConfirmationAlertDialog,
    DeleteConfirmationReservationAlert,
    ItemDeleteConfirmationAlertDialog,
    ProductAmountAlertDialog,
    SettingsAccessPopupPage,
    BalanceAlertDialog,
    OpenConfirmationAlertDialog,
    NewTemperatureAlertDialog,
    SignoutConfirmationAlertDialog,
    BadrCodeScannerAlert,
    SelectedCustomerAlert,
    EditCustomerAlert,
    DeleteConfirmationCustomerAlert,
    PartialAlertDialog,
    RetourModeAlertDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MatDialogModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    MatSelectModule,
    ReactiveFormsModule,
    ReceiptAlertPageModule,
    ProductOptionAlertPageModule,
    SettingsAccessPopupPageModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    OrderService,
    TableService,
    MenuService,
    CategoryService,
    ApiService,
    PrinterService,
    DailyReportService,
    IndexedDbService,
    ToastService,
    ReservationService,
    DatePipe,
    DeviceService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuard,
    AuthService,
    DeviceDetectorService,
    SignalRService,
    CashDeskService,
    CompanyService,
    CustomerService,
    BankCardTransactionService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
