import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductOptionAlertPage } from './product-option-alert.page';

const routes: Routes = [
  {
    path: '',
    component: ProductOptionAlertPage
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductOptionAlertPageRoutingModule {}
