import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RestAlertDialog } from './rest-alert/rest-alert';
import { OrderService } from 'src/app/shared/services/order.service';
import { Order } from 'src/app/shared/models/order.model';
import { PaymentMethod } from 'src/app/shared/models/enums/payment-method.enum';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';


@Component({
    selector: 'cash-alert-dialog',
    templateUrl: 'cash-alert.html',
    styleUrls: ['./cash-alert.scss'],
})
export class CashAlertDialog {

    price: string = " ";
    errorMessage: string = " ";
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CashAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private orderService: OrderService,
    ) {
        dialogRef.disableClose = true;
    }

    restAlert(goBack: boolean, rest, order: Order) {
        this.errorMessage = "";
        this.dialogRef.close();
        const dialogRef = this.dialog.open(RestAlertDialog, {
            width: '379px',
            data: { rest: rest, goBack: goBack, order: order }
        });
    }

    checkOutMoney(number: string) {
        this.errorMessage = "";
        let cptComma = 0;
        for (let index = 0; index < this.price.length; index++) {
            if (this.price[index] == ".") {
                cptComma++;
            }
        }
        if (cptComma == 0) {
            this.price = this.price + number;
        } else if (cptComma == 1) {
            let val = this.getNumberAfterComma(this.price);
            if (val == false && number != ".") {
                this.price = this.price + number;
            }
        }
    }

    clear() {
        this.price = (this.price).substring(0, (this.price.length - 1));
    }

    getNumberAfterComma(value: string) {
        var str = value.split(".", 2);
        if (str.length == 2) {
            if (str[1].length == 2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    closePopp() {
        this.dialogRef.close();
    }

    calculateRestAmount(): number {
        let rest: number;
        if (this.orderService.splittedOrder == null) {
            rest = parseFloat(this.price) - this.orderService.selectedOrder.getTotalToPay();
        } else {
            rest = parseFloat(this.price) - this.orderService.splittedOrder.getTotalToPay();
        }
        return rest;
    }

    payOrder() {
        let restAmount = this.calculateRestAmount();
        if (restAmount >= 0) {
            this.dialogRef.close();
            if (this.orderService.splittedOrder == null) {
                this.registerOrder(this.orderService.selectedOrder, restAmount, true, () => {
                    this.orderService.setSelectedOrder(null);
                });
            } else {
                this.registerOrder(this.orderService.splittedOrder, restAmount, false, () => {
                    this.orderService.splittedOrder = null;
                    ApplicationEvents.splittedOrderUpdate$.publish();

                });
            }
        } else {
            this.errorMessage = "ingevoerd bedrag is niet genoeg!";
        }
    }

    private registerOrder(order: Order, restAmount, goBack: boolean, callBack: Function) {
        order.paymentMethod = PaymentMethod.Cash;
        order.paidAmount = order.getTotalAmountWithDiscount();
        this.orderService.registerOrder(order, (data) => {
            try {
                callBack();
                this.restAlert(goBack, restAmount, order);
            } catch (error) {
                console.log("CheckOutPage: Error printerService.printerOrder");
                console.log(error);
            }
        }, (error) => {
            console.log("CheckOutPage: Error registerOrder");
        });
    }
}