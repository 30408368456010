import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/services/auth/auth.guard';
import { SigninComponent } from './views/signin/signin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full'
  },

  {
    path: 'signin',
    component: SigninComponent
  },

  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginPageModule)
  },

  {
    path: 'tabs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/tabs/tabs.module').then(m => m.TabsPageModule)
  },

  {
    path: 'check-out',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/check-out/check-out.module').then( m => m.CheckOutPageModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/settings/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'change-language',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/settings/change-language/change-language.module').then( m => m.ChangeLanguagePageModule)
  },
  {
    path: 'device-info',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/settings/device-info/device-info.module').then( m => m.DeviceInfoPageModule)
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/settings/report/report.module').then( m => m.ReportPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
