import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { Order } from 'src/app/shared/models/order.model';
import { OrderService } from 'src/app/shared/services/order.service';
import { RestAlertDialog } from '../cash-alert/rest-alert/rest-alert';
import { Location } from '@angular/common';

export interface dataDialog{
    selectedOrder: Order
}

@Component({
    selector: 'partial-alert-dialog',
    templateUrl: 'partial-alert.html',
    styleUrls: ['./partial-alert.scss'],
})
export class PartialAlertDialog implements OnInit{

    price: string = " ";
    activatedPaymentMethode: number = 0;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<PartialAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: dataDialog,
        private orderService: OrderService,
        private location: Location,
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(){
        if (this.data) {
            this.activatedPaymentMethode = this.data.selectedOrder.paymentMethod;
        }
    }

    restAlert(goBack: boolean, rest, order: Order) {
        if (this.data.selectedOrder) {
            this.dialogRef.close(order);
        }else{
            this.dialogRef.close();
        }
        const dialogRef = this.dialog.open(RestAlertDialog, {
            width: '379px',
            data: { rest: rest, goBack: goBack, order: order}
        });
    }

    checkOutMoney(number: string) {
        let cptComma = 0;
        for (let index = 0; index < this.price.length; index++) {
            if (this.price[index] == ".") {
                cptComma++;
            }
        }
        if (cptComma == 0) {
            this.price = this.price + number;
        } else if (cptComma == 1) {
            let val = this.getNumberAfterComma(this.price);
            if (val == false && number != ".") {
                this.price = this.price + number;
            }
        }
    }

    clear() {
        this.price = (this.price).substring(0, (this.price.length - 1));
        if(!this.price){
            this.dialogRef.close();
        }
    }

    getNumberAfterComma(value: string) {
        var str = value.split(".", 2);
        if (str.length == 2) {
            if (str[1].length == 2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    closePopp() {
        this.dialogRef.close();
    }

    calculateRestAmount() : number{
        let rest = 0;
        if (this.data) {
            rest = parseFloat(this.price) - this.data.selectedOrder.getTotalToPay();
        }else{
            if (!this.orderService.splittedOrder) {
                rest = parseFloat(this.price) - this.orderService.selectedOrder.getTotalToPay();
            } else {
                rest = parseFloat(this.price) - this.orderService.splittedOrder.getTotalToPay();
            }
        }
        return rest;
    }

    payOrder() {
        let restAmount = this.calculateRestAmount();
        if (!this.data) {
            if(restAmount < 0){
                this.dialogRef.close();
                if (this.orderService.splittedOrder == null) {
                    this.orderService.selectedOrder.paidAmount = parseFloat(this.price);
                    this.orderService.selectedOrder.paymentMethod = this.activatedPaymentMethode;
                    this.registerOrder(this.orderService.selectedOrder, ()=>{
                        this.orderService.setSelectedOrder(null);
                        this.location.back();
                    });
                } else {
                    this.orderService.splittedOrder.paidAmount = parseFloat(this.price);
                    this.orderService.splittedOrder.paymentMethod = this.activatedPaymentMethode;
                    this.registerOrder(this.orderService.splittedOrder, ()=>{
                        this.orderService.splittedOrder = null;
                        ApplicationEvents.splittedOrderUpdate$.publish();
                    });
                }
            }
        }else{
            this.data.selectedOrder.paidAmount += parseFloat(this.price);
            this.data.selectedOrder.paymentMethod = this.activatedPaymentMethode;
            if (this.data.selectedOrder.paidAmount >= this.data.selectedOrder.getTotalAmountWithDiscount()) {
                this.data.selectedOrder.paidAmount = this.data.selectedOrder.getTotalAmountWithDiscount();
            }
            this.orderService.orderPayment(this.data.selectedOrder,(data)=>{
                if (restAmount > 0) {
                    this.restAlert(false,restAmount,data);
                }else{
                    this.dialogRef.close();
                }
            }, (error)=>{
                // error
            });
        }
    }

    private registerOrder(order: Order, callBack: Function){
        order.paymentMethod = this.activatedPaymentMethode;
        this.orderService.registerOrder(order, (data) => {
          try {
            callBack();
          } catch (error) {
            console.log("CheckOutPage: Error printerService.printerOrder");
            console.log(error);
          }
        }, (error) => {
          console.log("CheckOutPage: Error registerOrder");
         });
    }

    onChangePaymentMethode(event: any){
        this.activatedPaymentMethode = parseInt(event.detail.value);
    }
}