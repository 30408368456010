import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { Order } from 'src/app/shared/models/order.model';
import { PrinterService } from 'src/app/shared/services/printer.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavController } from '@ionic/angular';

export interface ReceiptPrint {
  order: Order;
  goBack: boolean;
}

@Component({
  selector: 'app-receipt-alert',
  templateUrl: './receipt-alert.page.html',
  styleUrls: ['./receipt-alert.page.scss'],
})
export class ReceiptAlertDialog implements OnInit {
  order: Order = new Order();
  isMobilePlatForm = this.deviceService.isMobile() ? true : false ;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ReceiptPrint,
    public dialogRef: MatDialogRef<ReceiptAlertDialog>,
    private location: Location,
    private printerService: PrinterService,
    private deviceService: DeviceDetectorService,
    public navCtrl: NavController,
  ) { 

    dialogRef.disableClose = true;

  }

  ngOnInit() {
    this.order = this.data.order;
  }

  getReceip(){
    this.printerService.printerOrder(this.order);
    this.dialogRef.close();
    if(this.data.goBack){
      if (!this.isMobilePlatForm) {
        this.location.back();
      }else{
        this.navCtrl.navigateBack('/mobileOrderList');
      }
    }
  }

  refuseGetRececipt(){
    this.dialogRef.close();
    if(this.data.goBack){
      if (!this.isMobilePlatForm) {
        this.location.back();
      }else{
        this.navCtrl.navigateBack('/mobileOrderList');
      }
    }
  }
}
