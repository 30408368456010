import { DiscountType } from "./enums/discount-type.enum";
import { ProductOption } from './productOptionsCategory.model';

export class OrderItem {
    public id: string;
    public orderId: string;
    public productId: string;
    public productName: string;
    public price: number;
    public quantity: number;
    public taxPercentage: number;
    public isPriceIncludedTax: boolean;
    public discount: number;
    public discountType: DiscountType;
    public options: ProductOption[];
    public serialNumber: string;
    public localId: number;
    public index: number;

    getTotalPrice(): number {
        let amount = 0;
        if (this.isPriceIncludedTax) {
            amount = this.quantity * this.price;
        } else {
            amount = this.quantity * (this.price + (this.price * this.taxPercentage / 100));
        }
        return Number.parseFloat(amount.toFixed(2));
    }

    getTotalPriceIncludedOptions():number{
        return this.getTotalPrice() + this.getOptionsTotalAmount();
    }

    getTaxAmount() {
        if (this.isPriceIncludedTax) {
            return this.quantity * (this.price / (100 + this.taxPercentage) * this.taxPercentage);
        }
        return this.quantity * (this.price * this.taxPercentage / 100);
    }

    //todo fix options price
    getTotalPriceIncludeDiscount(): number {
        return this.getTotalPrice() + this.getOptionsTotalAmount() - this.getDiscountAmount();
    }

    getDiscountAmount(): number {
        if (this.discountType == DiscountType.Amount) {
            return this.price > 0 ? this.discount : this.discount * -1;
        }
        return  ((this.quantity *this.price) + this.getOptionsTotalAmount()) * this.discount / 100;
    }

    getPriceIncludeDiscount(): number {
        return this.price - this.getDiscountAmount();
    }

    getOptionAmount(option: ProductOption): number {
        if (option) {
            return option.price * this.quantity;
        } else {
            return 0;
        }
    }

    getOptionsTotalAmount(): number {
        let orderItemOptionsPrice = 0;
        if (this.options && this.options.length > 0) {
            for (let i = 0; i < this.options.length; i++) {
                const element = this.options[i];
                orderItemOptionsPrice += (element.price * this.quantity);
            }
        }

        return orderItemOptionsPrice;
    }
}