import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class ToastService{
        constructor(private toastCtrl: ToastController,){

    }
    
    async showToast(value: any) {
        const toast = await this.toastCtrl.create({
            message: value,
            duration: 3000,
            position: 'bottom',
            color: 'danger'
        });
        toast.present();
    }

    async showToastWithOk(value: any) {
        const toast = await this.toastCtrl.create({
          message: value,
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        });
        toast.present();
    }
}