import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReceiptAlertDialog, } from '../../receipt-alert/receipt-alert.page';
import { PrinterService } from 'src/app/shared/services/printer.service';


@Component({
    selector: 'rest-alert-dialog',
    templateUrl: 'rest-alert.html',
    styleUrls: ['./rest-alert.scss'],

})
export class RestAlertDialog {

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<RestAlertDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private printerService: PrinterService) { 
            dialogRef.disableClose = true; 
         }

    closePopp() {
        this.dialogRef.close();
    }

    isPaied() {
        this.printerService.openCashDrawer();
        const dialogRef = this.dialog.open(ReceiptAlertDialog, {
            width: '379px',
            data: {order: this.data.order, goBack: this.data.goBack}
        });
        this.dialogRef.close();
    }
}