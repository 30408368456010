import { Order } from 'src/app/shared/models/order.model';
import { LoadingController } from '@ionic/angular';
import { Product } from 'src/app/shared/models/product.model';
import { OrderItem } from 'src/app/shared/models/orderItem.model';
import { TableService } from './table.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ProductOption } from 'src/app/shared/models/productOptionsCategory.model';
import { IndexedDbService } from './indexedDb.service';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from './device.service';
import { SignalRService } from './signalR.service';
import { CashDeskMode } from 'src/app/shared/models/enums/cashDesk-mode';

@Injectable()
export class OrderService {

    private orderUrl = "/order/";
    private retourOrderUrl = this.orderUrl + "retour?id=";
    private paymentUrl = this.orderUrl + "pay?id=";
    orderObjectStore = "orders";

    orders: Order[] = new Array<Order>();
    splittedOrder: Order;
    state: string = 'isNotDisplayTablesState';

    selectedOrder: Order = null;
    selectedItem: OrderItem = new OrderItem();
    initProduct: Product = new Product();
    CashdeskModeList = Object.keys(CashDeskMode).filter(key => !isNaN(Number(CashDeskMode[key]))).map((a) => {
        return {
            text: a,
            value: CashDeskMode[a]
        }
    });

    constructor(
        private apiService: ApiService,
        private tableService: TableService,
        private loadingCtrl: LoadingController,
        private indexedDbService: IndexedDbService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private deviceService: DeviceService,
        private signalRService: SignalRService
    ) { }



    async refreshLocalDb(): Promise<void> {
        const dbOrders = await this.indexedDbService.getAll(this.orderObjectStore);
        this.orders = this.mapOrders(dbOrders);
        this.tableService.resetReservedTables(this.orders);
        ApplicationEvents.ordersUpdate$.publish();
        return await Promise.resolve();
    }

    addOrder(order: Order, onSuccess?, onError?) {
        this.apiService.get("/order/nextid", (localId) => {
            order.localId = localId;
            this.indexedDbService.addItem(this.orderObjectStore, order).then(orderAdded => {
                this.refreshLocalDb().then(() => {
                    this.setSelectedOrder(this.orders.find(o => o.localId == orderAdded.localId));
                    this.signalRService.addNewOrder(order);
                    if (onSuccess) {
                        onSuccess(order);
                    }
                }).catch((error) => {
                    console.error(error);
                 });
            }, (error) => {
                console.error(error);
             }).catch((error) => {
                console.error(error);
             });
        }, (error) => {
            if (onError) {
                onError(error)
            }
        });
    }

    updateSelectedOrder() {
        this.signalRUpdateOrder(this.selectedOrder);
    }

    signalRAddOrder(order: Order) {
        let orderV = this.orders.find(o => o.localId == order.localId);

        if (!orderV) {
            this.indexedDbService.addItem(this.orderObjectStore, order).then(orderAdded => {
                this.refreshLocalDb();
            }, (error) => { });
        }
    }

    signalRDeleteOrder(order: Order) {
        this.indexedDbService.deleteItem(this.orderObjectStore, order, (data) => {
            this.refreshLocalDb().then(() => {
                this.setSelectedOrder(null);
            });
        }, (error) => {
            this.translateService.get('toast_delete_order').subscribe((message) => {
                this.toastService.showToast(message);
            });
        });
    }

    signalRUpdateOrder(order: Order, isFromSignalR:boolean = false) {
        let orderV = this.orders.find(o => o.localId == order.localId);
        if (orderV) {
            this.indexedDbService.updateItem(this.orderObjectStore, order, (data) => {
                this.refreshLocalDb().then(() => {
                    if (this.selectedOrder && this.selectedOrder.localId == order.localId) {
                        let items = this.orders.find(o => o.localId == order.localId).items;
                        this.selectedOrder.items = items;
                        this.selectedOrder.discountType = order.discountType;
                        this.selectedOrder.discount = order.discount;
                        this.setSelectedOrder(this.selectedOrder);
                        if(!isFromSignalR){
                            this.signalRService.updateOrder(this.selectedOrder);
                        }
                    }
                });
            }, (error) => {
                this.translateService.get('toast_uodate_db_order').subscribe((message) => {
                    this.toastService.showToast(message);
                });
            })
        }
    }

    removeSelectedOrder() {
        this.indexedDbService.deleteItem(this.orderObjectStore, this.selectedOrder, (data) => {
            this.signalRService.deleteOrder(this.selectedOrder);
            this.refreshLocalDb().then(() => {
                this.setSelectedOrder(null);
            });
        }, (error) => {
            this.translateService.get('toast_delete_order').subscribe((message) => {
                this.toastService.showToast(message);
            });
        });
    }

    splitOrder(order: Order) {
        if (this.splittedOrder == null) {
            this.splittedOrder = new Order();
            this.splittedOrder.localId = order.localId;
            this.splittedOrder.orderType = order.orderType;
            this.splittedOrder.items = new Array<OrderItem>();
            this.splittedOrder.paidAmount = 0;
            this.splittedOrder.totalAmount = 0;
            this.splittedOrder.discount = 0;
            this.splittedOrder.tableId = order.tableId;
            this.splittedOrder.table = order.table;
            this.splittedOrder.isSpiltedOrder = true;
            this.splittedOrder.customer = order.customer;
            this.splittedOrder.customerId = order.customerId;
            ApplicationEvents.splittedOrderUpdate$.publish();
        } else {
            this.translateService.get('toast_split_order').subscribe((message) => {
                this.toastService.showToast(message);
            });
        }
    }

    addItem(product: Product, discountObject, options: ProductOption[]) {
        for (let index = 0; index < this.selectedOrder.items.length; index++) {
            let item = this.selectedOrder.items[index];
            if (item.productId == product.id && item.price == product.unitPrice) {
                if (this.compareOptions(options, item.options)) {
                    if (item.discountType && discountObject) {
                        if (item.discountType == discountObject.discountType
                            && item.discount == discountObject.amount) {
                            if (product.quantity) {
                                item.quantity += product.quantity;
                            } else {
                                item.quantity += 1;
                            }
                            this.updateSelectedOrder();
                            return;
                        }
                    } else if (!item.discountType && !discountObject) {
                        if (product.quantity) {
                            item.quantity += product.quantity;
                        } else {
                            item.quantity += 1;
                        }
                        this.updateSelectedOrder();
                        return;
                    }
                }
            }
        }
        let item = new OrderItem();
        item.productId = product.id;
        item.productName = product.name;
        item.price = product.unitPrice;
        if (product.quantity) {
            item.quantity = product.quantity;
        } else {
            item.quantity = 1;
        }
        if (discountObject) {
            item.discountType = discountObject.discountType;
            item.discount = discountObject.discount;
        } else {
            item.discount = 0;
        }
        item.isPriceIncludedTax = product.isPriceIncludedTax;
        item.taxPercentage = product.taxPercentage;
        item.options = options;
        if (!item.localId) {
            item.localId = this.selectedOrder.lastIndex;
            this.selectedOrder.lastIndex++;
        }
        this.selectedOrder.items.push(item);
        if (!this.initProduct) {
            this.updateSelectedOrder();
        }
    }

    compareOptions(options: ProductOption[], orderItemOptions: ProductOption[]) {
        if (options && orderItemOptions && options.length == orderItemOptions.length) {
            let cpt = 0;
            for (let index = 0; index < orderItemOptions.length; index++) {
                const orderItemOption = orderItemOptions[index];
                for (let i = 0; i < options.length; i++) {
                    const option = options[i];
                    if (option.name == orderItemOption.name) {
                        cpt++;
                    }
                }
            }
            if (cpt == orderItemOptions.length) {
                return true;
            } else {
                return false;
            }
        } else if (!options && !orderItemOptions) {
            return true;
        }
        return false;
    }

    updateOrderItem(orderItem: OrderItem) {
        let pos = this.selectedOrder.items.findIndex(i => i.productId == orderItem.productId && i.localId == orderItem.localId && i.discountType == orderItem.discountType && i.discount == orderItem.discount);

        if (orderItem && orderItem.quantity > 0) {
            this.selectedOrder.items[pos] = orderItem;
        }

        if (orderItem && orderItem.quantity == 0) {
            this.selectedOrder.items.splice(pos, 1);
        }

        this.updateSelectedOrder();
    }

    removeOrderItem(orderItem: OrderItem) {
        let pos = this.selectedOrder.items.findIndex(o => o.localId == orderItem.localId);
        this.selectedOrder.items.splice(pos, 1);
        this.updateSelectedOrder();
    }

    deleteItem(product: Product) {
        if (product != null && product.id) {
            this.removeItemFromOrder(null, product.id, this.selectedOrder);
            ApplicationEvents.ordersUpdate$.publish();
        }
    }

    addItemsToSplittedOrder(orderItem: OrderItem) {
        if (this.selectedOrder.items.length == 1 && this.selectedOrder.items[0].quantity == 1) {
            this.translateService.get('toast_add_item').subscribe((message) => {
                this.toastService.showToast(message);
            });
        } else {
            if (orderItem != null && orderItem.productId) {

                if (this.splittedOrder != null) {
                    for (let index = 0; index < this.splittedOrder.items.length; index++) {
                        let item = this.splittedOrder.items[index];
                        if (orderItem.productId == item.productId && orderItem.localId == item.localId) {
                            if (this.compareOptions(orderItem.options, item.options)
                                && item.discountType == orderItem.discountType && item.discount == orderItem.discount) {

                                if (item.quantity >= 0) {
                                    item.quantity++;
                                    ApplicationEvents.splittedOrderUpdate$.publish();
                                }

                                this.removeItemFromOrder(orderItem, null, this.selectedOrder);
                                ApplicationEvents.ordersUpdate$.publish();
                                return;
                            }
                        }
                    }

                    let newItem: OrderItem = new OrderItem();
                    newItem.productId = orderItem.productId;
                    newItem.productName = orderItem.productName;
                    newItem.price = orderItem.price;
                    newItem.quantity = 1;
                    newItem.isPriceIncludedTax = orderItem.isPriceIncludedTax;
                    newItem.taxPercentage = orderItem.taxPercentage;
                    newItem.options = orderItem.options;
                    newItem.localId = orderItem.localId;
                    newItem.discount = orderItem.discount;
                    newItem.discountType = orderItem.discountType;
                    this.splittedOrder.items.push(newItem);
                    ApplicationEvents.splittedOrderUpdate$.publish();

                    this.removeItemFromOrder(orderItem, null, this.selectedOrder);
                    ApplicationEvents.ordersUpdate$.publish();
                }
            }
        }
    }

    removeItemsFromSplittedOrder(orderItem: OrderItem) {
        this.removeItemFromOrder(orderItem, null, this.splittedOrder);
        ApplicationEvents.splittedOrderUpdate$.publish();

        let isIncreaseQuantity = false;
        for (let index = 0; index < this.selectedOrder.items.length; index++) {
            if (orderItem.productId == this.selectedOrder.items[index].productId && orderItem.localId == this.selectedOrder.items[index].localId) {
                if (this.compareOptions(this.selectedOrder.items[index].options, orderItem.options)) {
                    if (this.selectedOrder.items[index].quantity >= 0) {
                        this.selectedOrder.items[index].quantity++;
                        isIncreaseQuantity = true;
                        break;
                    }
                }
            }
        }
        if (!isIncreaseQuantity) {
            let newItem: OrderItem = new OrderItem();
            newItem.productId = orderItem.productId;
            newItem.productName = orderItem.productName;
            newItem.price = orderItem.price;
            newItem.quantity = 1;
            newItem.isPriceIncludedTax = orderItem.isPriceIncludedTax;
            newItem.taxPercentage = orderItem.taxPercentage;
            newItem.options = orderItem.options;
            newItem.localId = orderItem.localId;
            newItem.discount = orderItem.discount;
            newItem.discountType = orderItem.discountType;
            this.selectedOrder.items.push(newItem);
        }
        this.indexedDbService.updateItem(this.orderObjectStore, this.selectedOrder, (data) => {
            ApplicationEvents.ordersUpdate$.publish();
        }, (error) => {
            this.translateService.get('toast_uodate_db_order').subscribe((message) => {
                this.toastService.showToast(message);
            });
        });
    }

    async registerOrder(order: Order, onSuccess, onError) {
        let cashDesk = this.deviceService.getCashdeskFromLocalStorage();
        order.id = undefined;
        order.discount = 0; //todo fix this
        order.totalAmount = order.getTotalAmountWithDiscount();
        order.cashDeskMode = cashDesk.cashDeskMode;
        if (order.table && order.table.id) {
            order.tableId = order.table.id;
        }
        const loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();

        this.apiService.post(this.orderUrl + cashDesk.id, order, async (data) => {
            await loader.dismiss();
            order.id = data.id;
            onSuccess(order);
            if (!order.isSpiltedOrder) {
                this.indexedDbService.deleteItem(this.orderObjectStore, order, (data) => {
                    this.refreshLocalDb().then(() => {
                        this.signalRService.deleteOrder(order);
                    });
                }, (error) => { })
            }
        }, (error) => {
            loader.dismiss();
            onError(error);
        });
    }

    async registerRetourOrder(order: Order, onSuccess, onError) {
        let cashDesk = this.deviceService.getCashdeskFromLocalStorage();

        order.id = undefined;
        order.totalAmount = order.getTotalAmountWithDiscount();
        order.cashDeskMode = cashDesk.cashDeskMode;
        if (order.table && order.table.id) {
            order.tableId = order.table.id;
            order.table = undefined;
        }
        const loader = await this.loadingCtrl.create({ message: "loading...", spinner: 'dots' });
        await loader.present();

        this.apiService.post(this.retourOrderUrl + cashDesk.id, order, async (data) => {
            await loader.dismiss();
            order.id = data.id;
            onSuccess(order);
        }, (error) => {
            loader.dismiss();
            onError(error);
        });
    }

    getOrders() {
        return this.orders;
    }

    setSelectedOrder(order: Order) {
        this.selectedOrder = order;
        ApplicationEvents.selectedOrderUpdate$.publish();
        this.selectedItem = null;
        ApplicationEvents.selectedOrderItemUpdate$.publish();
    }

    setInitProduct(product: Product) {
        this.initProduct = product;
        ApplicationEvents.initProductUpdate$.publish();
    }

    getSelectedOrderItemsQuantity(productId: string) {
        if (this.selectedOrder != null && this.selectedOrder.items != null) {
            let count = 0;
            for (let index = 0; index < this.selectedOrder.items.length; index++) {
                if (productId == this.selectedOrder.items[index].productId) {
                    count += this.selectedOrder.items[index].quantity;
                }
            }
            return count;
        }
    }

    getSelectedOrderItems(productId: string) {
        if (this.selectedOrder != null && this.selectedOrder.items != null) {
            for (let index = 0; index < this.selectedOrder.items.length; index++) {
                if (productId == this.selectedOrder.items[index].productId) {
                    return this.selectedOrder.items[index].productId;
                }
            }
        }
    }

    private removeItemFromOrder(orderItem: OrderItem, productId: string, order: Order) {
        for (let index = 0; index < order.items.length; index++) {

            if (!orderItem && productId) orderItem = order.items.find(i => i.productId == productId);

            if (orderItem.productId == order.items[index].productId && orderItem.localId == order.items[index].localId) {

                if (order.items[index].quantity > 0 && this.compareOptions(order.items[index].options, orderItem.options)) {

                    order.items[index].quantity -= 1;
                    if (order.items[index].quantity == 0) {
                        order.items.splice(index, 1);
                        this.indexedDbService.updateItem(this.orderObjectStore, order, (data) => {
                            this.refreshLocalDb().then(() => {
                                if (order.localId) {
                                    this.signalRService.updateOrder(order);
                                    console.log("OrderService", "removeItemFromOrder: signalRService.updateOrder !!..")
                                    this.selectedItem = null;
                                    ApplicationEvents.selectedOrderItemUpdate$.publish();
                                }
                            });
                        }, (error) => {
                            this.translateService.get('toast_remove_item').subscribe((message) => {
                                this.toastService.showToast(message);
                            });
                        })
                        return;
                    } else {
                        this.indexedDbService.updateItem(this.orderObjectStore, order, (data) => {
                            this.refreshLocalDb().then(() => {
                                if (order.localId) {
                                    this.signalRService.updateOrder(order);
                                    console.log("OrderService", "removeItemFromOrder 2: signalRService.updateOrder !!..")

                                    // this.setSelectedOrder(this.orders.find(o => o.localId == order.localId));
                                    ApplicationEvents.selectedOrderUpdate$.publish();
                                }
                            });
                        }, (error) => {
                            this.translateService.get('toast_minimize_totaleAmount').subscribe((message) => {
                                this.toastService.showToast(message);
                            });
                        })
                        return;
                    }
                }
            }
        }
    }

    orderPayment(order, onSuccess, onError) {
        let cashDesk = this.deviceService.getCashdeskFromLocalStorage();
        this.apiService.put(this.paymentUrl + cashDesk.id, order, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    mapOrders(items: Order[]): Order[] {
        let orders = new Array<Order>();
        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            let order = this.mapOrder(element);
            orders.push(order);
        }
        return orders;
    }

    private mapOrder(element: Order) {
        let order = new Order();
        order.localId = element.localId;
        order.referenceOrderId = element.referenceOrderId;
        order.id = element.id;
        order.tableId = element.tableId;
        order.code = element.code;
        order.date = element.date;
        order.totalAmount = element.totalAmount;
        order.paidAmount = element.paidAmount ? element.paidAmount : 0;
        order.discount = element.discount;
        order.orderType = element.orderType;
        order.discountType = element.discountType;
        order.paymentMethod = element.paymentMethod;
        order.cashDeskMode = element.cashDeskMode;
        order.items = new Array<OrderItem>();
        order.table = element.table;
        order.isSpiltedOrder = element.isSpiltedOrder;
        if (element.items) {
            for (let i = 0; i < element.items.length; i++) {
                const itemElement = element.items[i];
                let orderItem = new OrderItem();
                orderItem.id = itemElement.id;
                orderItem.localId = itemElement.localId;
                orderItem.orderId = itemElement.orderId;
                orderItem.productId = itemElement.productId;
                orderItem.productName = itemElement.productName;
                orderItem.price = itemElement.price;
                orderItem.quantity = itemElement.quantity;
                orderItem.taxPercentage = itemElement.taxPercentage;
                orderItem.isPriceIncludedTax = itemElement.isPriceIncludedTax;
                orderItem.discount = itemElement.discount;
                orderItem.discountType = itemElement.discountType;
                orderItem.options = itemElement.options;
                order.items.push(orderItem);
            }
        }

        return order;
    }
}