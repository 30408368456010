import { Injectable } from "@angular/core";
import { Order } from "../models/order.model";
import { DatePipe } from '@angular/common';
import { DailyReport } from 'src/app/shared/models/dailyreport.model';
import { ApplicationEvents } from 'src/app/shared/utils/event.constant';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from "./device.service";
import { CompanyService } from "./company.service";
import { Company } from "src/app/shared/models/company.model";
import { CashDesk } from "src/app/shared/models/cashDesk.model";


declare var epson: any;

@Injectable()
export class PrinterService {
  ePosDev: any;
  printer: any;
  isOnline: boolean;
  ctx: CanvasRenderingContext2D;
  private imageLogo: HTMLImageElement;
  private _company: Company;
  private _cashDesk: CashDesk;

  constructor(
    private datepipe: DatePipe,
    private toastService: ToastService,
    private translateService: TranslateService,
    private deviceService: DeviceService,
    private companyService: CompanyService
  ) {
    this.ePosDev = new epson.ePOSDevice();
    this.ePosDev.onreconnecting = function(){
      console.log('this.ePosDev.onreconnecting..');
    }
    this.ePosDev.onreconnect = function(){
      console.log('this.ePosDev.onreconnect..');
    }
    this.ePosDev.ondisconnect = function(){
      console.log('this.ePosDev.ondisconnect..');
    }
    
    this.connect();
  }

  connect() {
    this._company = this.companyService.getInfo();
    if (this._company) {
      var canvas = document.createElement("canvas");
      canvas.height = 200; //get original canvas height
      canvas.width = 270;
      this.ctx = canvas.getContext("2d");
      this.imageLogo = new Image();
      this.imageLogo.width = 200;
      this.imageLogo.height = 270;
      this.imageLogo.onload = () => {
        this.ctx.drawImage(this.imageLogo, 0, 0);
      };
      this.imageLogo.src = this._company.logo;
    }

    this._cashDesk = this.deviceService.getCashdeskFromLocalStorage();

    if (this._cashDesk) {
      console.log("try to connect to the printer");
      try {
        this.ePosDev.connect(this._cashDesk.printer.ip, '8043', (resultConnect) => {
          console.log("ePosDev.connect resultConnect: " + resultConnect);
          let deviceId = 'local_printer';
          let options = { 'crypto': false, 'buffer': false };
          if ((resultConnect == 'OK') || (resultConnect == 'SSL_CONNECT_OK')) {
            //Retrieves the Printer object
            this.ePosDev.createDevice(deviceId, this.ePosDev.DEVICE_TYPE_PRINTER, options, (deviceObj, errorCode) => {
              if (deviceObj === null) {
                //Displays an error message if the system fails to retrieve the Printer object
                console.log("PrinterService: Displays an error message if the system fails to retrieve the Printer object");
                return;
              }
              this.printer = deviceObj;
              this.isOnline = true;
              ApplicationEvents.printerStatusUpdate$.publish();
              this.printer.ononline = function () {
                console.log("PrinterService: printer.ononline");

                this.isOnline = true;
                ApplicationEvents.printerStatusUpdate$.publish();
              }
              this.printer.onoffline = function () {
                console.log("PrinterService: printer.onoffline");

                this.isOnline = false;
                ApplicationEvents.printerStatusUpdate$.publish();
                this.connect();
              }

              //Registers the print complete event
              this.printer.onreceive = function (response) {
                if (response.success) {
                  //Displays the successful print message
                  console.log("PrinterService: Displays the successful print message");
                }
                else {
                  //Displays error messages
                  console.log("PrinterService: Displays the ERROR print message");
                }
              };
            });
          }
          else {
            //Displays error messages: ERROR_TIMEOUT - 
            console.log("error connecting to the printer: resultConnect - " + resultConnect);
            this.connect();
          }
        }, (error) => {
          console.log("error connecting to the printer: error - " + error);

        });
      } catch (exeption) {
        console.log("error connecting to the printer: exeption - " + exeption);
      }
    }
  }

  printerOrder(order: Order) {

    console.log("try  printerOrder");
    if (!this.printer) {
      this.connectedErrorToast();
      return;
    }

    let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let currentTime = this.datepipe.transform(new Date(), 'HH:mm');

    console.log("currentDate: " + currentDate);
    console.log("currentTime: " + currentTime);
    this.printer.addLayout(this.printer.LAYOUT_RECEIPT, 700, 0, 0, 0, 35, 30);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);

    this.printer.addTextSmooth(true);

    if (order.id) {
      if (this._cashDesk.printer.showLogoInReceipt) {
        this.printer.addImage(this.ctx, 0, 0, 270, 200, undefined, this.printer.MODE_MONO);
        this.printer.addText('\n');
        this.printer.addText('\n');
      }
      this.printer.addTextDouble(true, true);
      this.printer.addText(this._company.name + '\n');
      this.printer.addTextDouble(false, false);
      this.printer.addText(this._company.address + " " + this._company.houseNumber + '\n');
      this.printer.addText(this._company.postalCode + " " + this._company.city + '\n');
      this.printer.addText(this._company.phone);
      this.printer.addText('\n');
      this.printer.addText('\n');

      if (this._cashDesk.printer.receiptHeader) {
        this.printer.addText(this._cashDesk.printer.receiptHeader);
        this.printer.addText('\n');
        this.printer.addText('\n');
      }
    }

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('DATUM: ' + currentDate + '\t\t');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText('TIJD: ' + currentTime + '\n');

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    if (order.id) {
      this.printer.addText('# ' + order.id + '\n');
    } else {
      if (order.table && order.table.name) {
        this.printer.addText('Tafel # ' + order.table.name + '\n');
      }
    }

    this.printer.addText('------------------------------------------------');

    for (let index = 0; index < order.items.length; index++) {
      const product = order.items[index];
      this.printer.addText('\n');
      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addText(this.getQuantityText(product.quantity) + " " + this.getProductNameText(product.productName) + "  " + this.getPriceText(product.getTotalPrice().toFixed(2)));
      if (product.options && product.options.length > 0) {
        for (let j = 0; j < product.options.length; j++) {
          const option = product.options[j];
          this.printer.addText('\n');
          this.printer.addTextAlign(this.printer.ALIGN_LEFT);
          this.printer.addText("     " + this.getProductNameText("- " + option.name) + "  " + this.getPriceText(option.price.toFixed(2)));
        }
      }
    }

    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText('------------------------------------------------');

    this.printer.addText('\n');
    this.printer.addTextDouble(true, true);
    this.printer.addText('Totaal: ' + order.getTotalAmountWithDiscount());
    this.printer.addTextDouble(false, false);
    this.printer.addText('\n');
    this.printer.addText('\n');

    if (order.id) {
      var taxAmounts = order.getTaxAmounts();
      for (let index = 0; index < taxAmounts.length; index++) {
        const element = taxAmounts[index];
        this.printer.addText('incl. ' + element.key + ' % btw  (b):  ' + element.amount.toFixed(2));
        this.printer.addText('\n');
      }
      this.printer.addText('\n');
      this.printer.addText('\n');

      if (this._cashDesk.printer.receiptFooter) {
        this.printer.addTextAlign(this.printer.ALIGN_CENTER);
        this.printer.addText(this._cashDesk.printer.receiptFooter);
      }
    }
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addCut(this.printer.CUT_FEED);

    console.log("PrinterService: send to printer");
    this.sendToPrinter();
  }

  private getQuantityText(quantity: number) {
    let result = "";
    if (quantity > 9999) {
      result = "999+";
    } else {
      for (let index = 0; index < 4 - quantity.toString().length; index++) {
        result += " ";
      }
      result += quantity.toString();
    }
    return result;
  }
  private getPriceText(price: string) {
    let result = "";
    // if(!orderId){
    //   return result;
    // }
    if (price.length > 9) {
      result = "999999.9+";
    } else {
      for (let index = 0; index < 9 - price.toString().length; index++) {
        result += " ";
      }
      result += price.toString();
    }
    return result;
  }

  private getProductNameText(name: string) {
    let result = name;
    if (name.length < 32) {
      for (let index = 0; index < 32 - name.length; index++) {
        result += " ";
      }
    }
    return result;
  }

  printerDailyReport(report: DailyReport) {
    if (!this.printer) {
      this.connectedErrorToast();
      return;
    }

    let CompanyName = "Marhaba";
    let CompanyHeader = "Dagrapport";


    console.log("PrinterService: start create layout to printer");

    this.printer.addLayout(this.printer.LAYOUT_RECEIPT, 800, 0, 0, 0, 35, 0);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addLogo(32, 32);

    this.printer.addTextSmooth(true);
    this.printer.addText('\n');
    this.printer.addText('\n');

    this.printer.addTextDouble(true, true);
    this.printer.addText(this._company.name + '\n');

    this.printer.addTextDouble(false, false);
    this.printer.addText(CompanyHeader + '\n');
    this.printer.addText('\n');

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('DATUM: ' + report.date + '\t\t');
    this.printer.addText('\n');
    this.printer.addText('\n');

    this.printer.addTextAlign(this.printer.ALIGN_CENTER);
    this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
    this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
    this.printer.addTextDouble(false, true);

    this.printer.addText('* ******* *\n');

    this.printer.addTextDouble(false, false);

    console.log("PrinterService: Add order items to printer");

    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText("Pin" + '\t');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText(report.pin.toFixed(2));

    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText("Cash" + '\t');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText(report.cash.toFixed(2));

    console.log("PrinterService: order items added to printer");
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText('Totaal: ' + report.revenue.toFixed(2));
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addText('\n');

    this.printer.addCut(this.printer.CUT_FEED);

    console.log("PrinterService: send to printer");
    this.sendToPrinter();
  }

  sendToPrinter() {
    if (!this.printer) {
      this.connectedErrorToast();
      return;
    }
    if (this.ePosDev.isConnected) {
      this.printer.send();
    }
  }

  openCashDrawer() {
    if (!this.printer) {
      this.connectedErrorToast();
      return;
    }

    if (this.printer && this.ePosDev.isConnected) {
      this.printer.addPulse(this.printer.DRAWER_HIGH, this.printer.PULSE_100);
      this.printer.send();
    }
  }

  connectedErrorToast() {
    this.translateService.get('toast_printer_not_connected').subscribe((message) => {
      this.toastService.showToastWithOk(message);
    });
  }

  printOrdersByProduct(products: any[]) {
    if (!this.printer) {
      this.connectedErrorToast();
      return;
    }

    let currentDate = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    let currentTime = this.datepipe.transform(new Date(), 'HH:mm');

    this.printer.addLayout(this.printer.LAYOUT_RECEIPT, 700, 0, 0, 0, 35, 30);
    this.printer.addTextAlign(this.printer.ALIGN_CENTER);

    this.printer.addTextSmooth(true);

    if (this._cashDesk.printer.showLogoInReceipt) {
      this.printer.addImage(this.ctx, 0, 0, 270, 200, undefined, this.printer.MODE_MONO);
      this.printer.addText('\n');
      this.printer.addText('\n');
    }

    this.printer.addTextDouble(true, true);
    this.printer.addText("Overzicht" + '\n');
    this.printer.addTextDouble(false, false);
    this.printer.addText('\n');
    this.printer.addText('\n');

    if (this._cashDesk.printer.receiptHeader) {
      this.printer.addText(this._cashDesk.printer.receiptHeader);
      this.printer.addText('\n');
      this.printer.addText('\n');
    }

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);
    this.printer.addText('DATUM: ' + currentDate + '\t\t');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText('TIJD: ' + currentTime + '\n');

    this.printer.addTextAlign(this.printer.ALIGN_LEFT);

    this.printer.addText('# Overzicht\n');

    this.printer.addText('------------------------------------------------');

    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      this.printer.addText('\n');
      this.printer.addTextAlign(this.printer.ALIGN_LEFT);
      this.printer.addText(this.getQuantityText(product.quantity) + " " + this.getProductNameText(product.name) + "  " + this.getPriceText(""));
      if (product.subItems && product.subItems.length > 0) {
        for (let j = 0; j < product.subItems.length; j++) {
          const option = product.subItems[j];
          this.printer.addText('\n');
          this.printer.addTextAlign(this.printer.ALIGN_LEFT);
          this.printer.addText("     " + this.getProductNameText("- " + option.quantity + " - " + option.name));
        }
      }
    }

    this.printer.addText('\n');
    this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
    this.printer.addText('------------------------------------------------');
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addText('\n');
    this.printer.addCut(this.printer.CUT_FEED);

    console.log("PrinterService: send to printer");
    this.sendToPrinter();

  }
}