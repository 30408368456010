import { Component, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ProductTemperature } from 'src/app/shared/models/productTemperature.model';


@Component({
    selector: 'new-temperature-popup-dialog',
    templateUrl: 'new-temperature-popup.html',
    styleUrls: ['./new-temperature-popup.scss'],
})
export class NewTemperatureAlertDialog {
    @ViewChild("note", {static:false}) note: any;
    value: string = " ";
    
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<NewTemperatureAlertDialog>,
    ) {
        dialogRef.disableClose = true;
    }

  

    getNumber(number: string) {
        let cptComma = 0;
        for (let index = 0; index < this.value.length; index++) {
            if (this.value[index] == ".") {
                cptComma++;
            }
        }
        if (cptComma == 0) {
            this.value = this.value + number;
        } else if (cptComma == 1) {
            let val = this.getNumberAfterComma(this.value);
            if (val == false && number != ".") {
                this.value = this.value + number;
            }
        }
    }

    clear() {
        this.value = (this.value).substring(0, (this.value.length - 1));
    }

    getNumberAfterComma(value: string) {
        var str = value.split(".", 2);
        if (str.length == 2) {
            if (str[1].length == 2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    closePopp() {
        this.dialogRef.close();
    }

    addButtonClick() {
        if (this.value != " ") {
            let newTemperature = new ProductTemperature();
            newTemperature.value = parseInt(this.value);
            newTemperature.note = this.note.value;
            this.dialogRef.close(newTemperature);
        }
    }


}