import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PrinterService } from './printer.service';
import { DeviceService } from './device.service';
import { Company } from 'src/app/shared/models/company.model';

@Injectable()
export class CompanyService{
    url = "/company?cashdesk=";
    
    constructor(
        private apiService: ApiService,
        private deviceService :DeviceService
    ) { 
        
    }

    get(onSuccess, onError) {
        if(!this.deviceService.getCashdeskFromLocalStorage()){
            console.log("error getting company data");
            return;
        }
        let fullUrl = this.url + this.deviceService.getCashdeskFromLocalStorage().id;
        this.apiService.get(fullUrl, (data) => {
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    }

    refresh() {
        if(!this.deviceService.getCashdeskFromLocalStorage()){
            console.log("error getting company data");
            return;
        }
        let fullUrl = this.url + this.deviceService.getCashdeskFromLocalStorage().id;
        this.apiService.get(fullUrl, (data) => {
            localStorage.setItem("company", JSON.stringify(data));
        }, (error) => {
            console.log("error refreshing company info..");
        });
    }

    getInfo() : Company{
        return JSON.parse(localStorage.getItem("company")) as Company;
    }
}