export class ProductOptionCategory{
    public name: string;
    public hasMultiValue: boolean;
    public options: ProductOption[];
}

export class ProductOption{
    public categoryName?:string;
    public name: string;
    public price: number;
}